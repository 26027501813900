


//const API_URL = "https://qa-test-i3pwk3ix5a-el.a.run.app/api/v1/";
 //const API_URL = !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://devfe.buktec.co.in/api/v1/";
 const API_URL = !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://devapi.buktec.co.in/api/v1/";
//  const API_URL = !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://qabetest.buktec.co.in/api/v1/";
// const API_URL = !!process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://uatbe.buktec.co.in/api/v1/";

//Managing devdoc api url

const DOCAI_URL =  !!process.env.REACT_APP_DOCAI_URL ? process.env.REACT_APP_DOCAI_URL :"https://devdocai.buktec.co.in/api/v1/";
//  const DOCAI_URL =  !!process.env.REACT_APP_DOCAI_URL ? process.env.REACT_APP_DOCAI_URL : "https://qadocai.buktec.co.in/api/v1/";
// const DOCAI_URL =  !!process.env.REACT_APP_DOCAI_URL ? process.env.REACT_APP_DOCAI_URL : "https://uatdocai.buktec.co.in/api/v1/";

const RECAPTCHA_SITE_KEY = !! process.env.REACT_APP_RECAPTCHA ? process.env.REACT_APP_RECAPTCHA : "6LeJXygaAAAAAIWeAagNqB-s5OdAvApFpZq7iHAB";
const ENV = !! process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : "DEVELOPMENT";

const CLARITY_KEY = !!process.env.REACT_CLARITY_KEY ? process.env.REACT_CLARITY_KEY : "guf4yiiaal"
const DOCAI_ENV_KEY = !!process.env.REACT_APP_DOCAI_ENV ? process.env.REACT_APP_DOCAI_ENV : "dev";

export {API_URL,
    RECAPTCHA_SITE_KEY,
    ENV,
    CLARITY_KEY,
    DOCAI_URL,
    DOCAI_ENV_KEY
};
