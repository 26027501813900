import React from 'react'
import TaskManager from './TaskManager';
import CreateTask from './CreateTask';
import { Route, Switch } from 'react-router-dom';
import TaskManagerService from '../../Services/TaskManager/TaskManagerService';
import { changeDateFormate } from '../../Helpers/DateFormat';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { setTaskCount, setTaskLoadingStatus, setPageNo, setTotalCount, setFinancialYearName, setFilterDateRange, setFilterCard, setTaskTempData,setPagePerItem } from "../../Redux/Actions/TaskManager";
import StorageData from '../../Helpers/StorageData';
import SessionManagement from '../../Utils/SessionManagement';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';

class TaskManagerView extends React.Component {
  constructor(props) {
    super(props);
    let default_yr = StorageData.getDefaultFinancialYear();
    this.state = {
      // is_search_applied: false,
      task_list: [],
      disabled_tasks: [],


      has_next: false,
      has_previous: false,
      page_no: 1,

      year_type: default_yr.year_type,
      start_date: default_yr.start_date,
      end_date: default_yr.end_date,

      search: "",
      filter_card: 0,
      filter_by: 1,
      sort_order: 1,
      sort_by: 1,

      filter_data: {
        is_star: 0,
        due_start_date: "",
        due_end_date: "",
        assigned_by: [],
        assigned_to: [],
        priority: ""
      },

      assigned_by_arr: [],
      assigned_to_arr: [],

      searchText: "",             // Ram
      isSearchApplied: false,     // Ram

    }
  }

  financialYearChanged = (year_type, start_date, end_date) => {
    this.setState({
      year_type,
      start_date,
      end_date,
      page_no: 1
    }, () => {
      this.getTaskList();
      this.getTaskCount();
      this.setFilterDateRange(year_type, start_date, end_date)
    })
  }

  setFilterDateRange = (year_type, start_date, end_date) => {
    this.props.dispatch(setFinancialYearName(year_type))
    if (year_type == 6)
      this.props.dispatch(setFilterDateRange(start_date, end_date))

  }

  onCardSelected = (task_card_filter) => {
    this.setState({
      filter_card: task_card_filter,
      page_no: 1
    }, () => {
      this.getTaskList()
      this.props.dispatch(setFilterCard(task_card_filter))

    })
  }

  onSortClick = (sort_order, sort_by) => {
    this.setState({
      sort_order,
      sort_by
    }, () => this.getTaskList())
  }

  searchFilter = (searchtxt) => {
    this.setState({
      search: searchtxt,
      page_no: 1
    }, () => { searchtxt ? this.getTaskList(1) : this.getTaskList(0) })
  }

  applyFilter = (type) => {
    this.setState({
      page_no: 1
    }, () => {
      type == "apply" ? this.getTaskList(2) : this.getTaskList(0);
    })
  }

  refreshItem = () => {
    this.getTaskList();
    this.getTaskCount();
  }

  getTaskList = (filter_by = this.state.filter_by) => {
    this.setState({
      filter_by: filter_by,
      task_list: []
    })
    this.props.dispatch(setTaskLoadingStatus(true));
    this.props.dispatch(setAppilcationLoder(true));

    let assigned_by = this.props.assigned_by.map((el) => el.id)
    let assigned_to = this.props.assigned_to.map((el) => el.id)
    let filter_data = {
      "per_page_items": this.props.per_page_items,
      "page_no": this.state.page_no,
      "date_filter": {
        "start_date": changeDateFormate(this.state.start_date),
        "end_date": changeDateFormate(this.state.end_date)
      },
      "filter_card": this.state.filter_card,
      "filter": filter_by,
      "search": this.state.search,
      "filter_box": {
        "from_date": changeDateFormate(this.props.due_start_date),
        "to_date": changeDateFormate(this.props.due_end_date),
        "priority": this.props.priority !== '' ? this.props.priority : null,
        "is_star": this.props.is_star !== '' ? this.props.is_star : null,
        "assigned_by": assigned_by.length === 0 ? null : assigned_by,
        "assigned_to": assigned_to.length === 0 ? null : assigned_to,
      },
      "sort_by": this.state.sort_by,
      "sort_order": this.state.sort_order
    };
    TaskManagerService.getTaskList(filter_data).then(
      (data) => {
        this.props.dispatch(setTaskLoadingStatus(false));
        this.props.dispatch(setAppilcationLoder(false));

        if (data.response_code == 200) {
          this.setState({
            task_list: data.result.tasks,

            page_no: data.result.page_no,
            total_count: data.result.total_count,
            total_pages: data.result.total_pages,

            has_next: data.result.has_next,
            has_previous: data.result.has_previous

          });
          this.setDisabledTaskList(data.result.tasks)
          this.props.dispatch(setPagePerItem(data.result.per_page_items))
          this.props.dispatch(setTotalCount(data.result.total_count))
          this.props.dispatch(setTaskCount(data.result.task_counts))
          this.props.dispatch(setPageNo(data.result.page_no))
        } else {
          toast.error(data.message);
        }
      });
  }

  setDisabledTaskList = (list) => {
    let temp_list = list.filter(task => {
      if (SessionManagement.getUserDetail().full_name == task.assigned_by) {
        return task
      }
    })
    this.setState({
      disabled_tasks: temp_list.map(task => {
        return ''+task.id
      })
    })
  }

  getTaskCount = () => {
    let filter_data = {
      "start_date": changeDateFormate(this.state.start_date),
      "end_date": changeDateFormate(this.state.end_date)
    };
    this.props.dispatch(setTaskLoadingStatus(true));
    TaskManagerService.getTaskCount(filter_data).then(
      (data) => {
        this.props.dispatch(setTaskLoadingStatus(false));
        if (data.response_code == 200) {
          this.props.dispatch(
            setTaskCount(data.result.task_count)
          );

        } else {
          toast.error(data.message);
        }
      });
  }

  sortFilterArr = (a, b)=>{
    let data_a = a.full_name.toUpperCase(); 
    let data_b = b.full_name.toUpperCase();
    if (data_a < data_b) {
      return -1;
    }
    if (data_a > data_b) {
      return 1;
    }
  }

  getFilterData = () => {
    this.props.dispatch(setTaskLoadingStatus(true));
    TaskManagerService.getFilterData().then(
      (data) => {
        this.props.dispatch(setTaskLoadingStatus(false));
        if (data.response_code == 200) {
          this.setState({
            assigned_by_arr: data.result.assigned_by.sort(this.sortFilterArr),
            assigned_to_arr: data.result.assigned_to.sort(this.sortFilterArr),
          })

        } else {
          toast.error(data.message);
        }
      });
  }

  makeRemoveStar = () => {
    let task_list = this.state.task_list;
    let index = task_list.findIndex(task => task.id === this.props.task_id);
    let task = task_list[index];
    task.is_star = this.props.changed_star;
    task_list[index] = task;
    this.setState({
      task_list
    })
  }

  changeTaskStatus = (selected_rows, status) => {
    this.getTaskCount();
    this.getTaskList()
    //console.log(status)
    let task_list = this.state.task_list.map((task) => {
      if (selected_rows.indexOf(task.id) != -1) {
        var duedate = task.due_date.split("-").reverse().join("-");
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;
        
        if(status == 1 && Date.parse(duedate) < Date.parse(formattedDate)) {
          task.status = 3;
        } else {
          task.status = status;
        }
      }
      return task;
    });
  
    this.setState({
      task_list
    });
  }

  newTask = (task, action) => {
    if (action === 'update') { 
      let task_list = this.state.task_list;
      let index = task_list.findIndex(item => item.id == task.id);
      var duedate = task.due_date.split("-").reverse().join("-");
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      console.log(":::::due date:::",Date.parse(duedate))
      console.log(":::::formated date:::::",Date.parse(formattedDate))
      if(task.status == 1 && Date.parse(duedate) < Date.parse(formattedDate)) {
        task.status = 3;
      } 

      task_list[index] = task;
      this.getTaskCount();
      this.setState({ task_list })
    }
    if (action === 'delete') {
      this.refreshItem()
    }
    if (action === 'save') {
      this.getTaskCount();
      this.setState({
        task_list: [task, ...this.state.task_list]
      })
    }

  }

  // -----------------> Ram

  changeSearchText = (search) =>{
    this.setState({
      searchText: search
    })
  }

  changeSearchStatus = (status) => {
    this.setState({
      isSearchApplied: status
    })
  }

  // -----------------> Ram
  componentDidMount() {
    this.props.dispatch(setTaskTempData({}))
    this.getTaskList(0);
    this.getTaskCount();
    this.getFilterData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.page_no != this.props.page_no) {
      this.setState({
        page_no: this.props.page_no
      }, () => this.getTaskList())
    }
    if (prevProps.task_id != this.props.task_id || prevProps.changed_star != this.props.changed_star) {
      this.makeRemoveStar();
    }
  }

  render() {
    
    return (
      <div>
        <ScrollPage />
        <Switch>
          <Route path="/task-manager/create/:task_id?"
            render={() => {
              return <CreateTask
                isSearchApplied={this.state.isSearchApplied}   //-----> Ram
                changeSearchStatus={this.changeSearchStatus}
                searchText={this.state.searchText}
                searchFilter={this.searchFilter}
                filter_by={this.state.filter_by}
                changeSearchText={this.changeSearchText}       //-----> Ram
                task_list={this.state.task_list}
                applyFilter={this.applyFilter}
                assigned_by_arr={this.state.assigned_by_arr}
                assigned_to_arr={this.state.assigned_to_arr}
                newTask={this.newTask}
                has_next={this.state.has_next}
                has_previous={this.state.has_previous}
                per_page_items={this.props.per_page_items}
                page_no={this.state.page_no}
                total_pages={this.state.total_pages}

                filter_card={this.state.filter_card}
              />
            }
            }
            exact />
          <Route path="/task-manager"
            render={() => {
              return <TaskManager
                isSearchApplied={this.state.isSearchApplied}   //-----> Ram
                changeSearchStatus={this.changeSearchStatus}
                searchText={this.state.searchText}
                changeSearchText={this.changeSearchText}       //-----> Ram
                filter_by={this.state.filter_by}
                sort_by={this.state.sort_by}
                sort_order={this.state.sort_order}
                onSortClick={this.onSortClick}
                searchFilter={this.searchFilter}
                task_list={this.state.task_list}
                filter_card={this.state.filter_card}
                applyFilter={this.applyFilter}
                assigned_by_arr={this.state.assigned_by_arr}
                assigned_to_arr={this.state.assigned_to_arr}
                financialYearChanged={this.financialYearChanged}
                onCardSelected={this.onCardSelected}
                changeTaskStatus={this.changeTaskStatus}
                refreshItem={this.refreshItem} 
                disabled_tasks={this.state.disabled_tasks}
                filter_by={this.state.filter_by}
                />
            }
            }
            exact />
        </Switch>
      </div>

    )
  }
}


function mapStateToProps(state) {
  const all_value = state.TaskManager
  const assigned_by = all_value.assigned_by || [];
  const assigned_to = all_value.assigned_to || [];
  const is_star = all_value.is_star || '';
  const due_start_date = all_value.due_start_date || null;
  const due_end_date = all_value.due_end_date || null;
  const priority = all_value.priority || null;

  const total_count = all_value.total_count || 0;
  const page_no = all_value.page_no || 1;
  const per_page_items = all_value.per_page_items || 0;

  const task_id = all_value.task_id || null;
  const changed_star = all_value.changed_star || 0;
  
  return {
    is_star, due_start_date, due_end_date, assigned_by, assigned_to, priority,
    total_count, page_no, per_page_items, task_id, changed_star
  };
}


export default connect(mapStateToProps)(TaskManagerView);