import React from 'react'
import { Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { changeDateFormate, dateWithMonthName } from '../../Helpers/DateFormat';
import LinkTransactionView from './LinkTransactionsView';
import MoneyLinkList from './MoneyLinkList';
import MoneyLinkBulkList from './BulkLedger/MoneyLinkBulkList';
import BulkLedgerForm from './BulkLedger/BulkLedgerForm';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import StorageData from "../../Helpers/StorageData";
import MasterService from '../../Services/MasterService';
import { connect } from 'react-redux';
import { setFilterBy, setFilterCard, setFilterDateRange, setFinancialYearName, setTransactionLoadingStatus, setBulkTransactionList, setCrDrType, setMoneyLinkCardFilter, setFinancialInstituteName, setPageNo } from '../../Redux/Actions/MoneyLink';
import { setAppilcationLoder } from '../../Redux/Actions/App';
import { LEDGER_ENTRIES_CODE, LINKING_CODE, VIEW_LINKED_ENTRIES_CODE } from '../../Helpers/Constants';
import { confirm as oldconfirm } from '../../Components/BoilerPlate/Confirm';
import ScrollPage from '../../Components/BoilerPlate/ScrollPage';
import { showAliasAcctNo } from '../../Helpers/HelperFunction';
import { confirm } from "react-confirm-box";
import { Button, Modal } from 'react-bootstrap';
import { Redirect } from 'react-router'
import TransactionReset from './BulkLedger/TransactionReset';

const options = {
    render: (message, onConfirm, onCancel) => {
        return (
            <>
                {/* <h1> Replace with {message} </h1>
		        <button onClick={onConfirm}> Yes </button> */}
                <Modal
                    show={true}
                    backdrop="static"
                    keyboard={false}
                    centered
                    // className="save-model-sec er-pop"
                    className="popup-model error-popup"
                >
                    <Modal.Body className="clearfix ">
                        <p className="pmsg-blue-center">
                            Alert
                        </p>
                        <p className="pmsg-text pmsg-gray-center">
                            {message}
                        </p>

                        <div className="center_apply_btn_new">
                            <Button onClick={onCancel} variant="outline-success reset_btn_new">No</Button>
                            <Button onClick={onConfirm} variant="success apply_btn_new">Yes</Button>
                        </div>
                    </Modal.Body>

                    {/* <Modal.Footer className='bottom-save-btn'>
                        <Button onClick={onCancel} variant="" className={"btn model-save-btn save-btn graybtn alert"}>No</Button>
                        <Button onClick={onConfirm} variant="" className="btn save-btn greenbtn model-save-btn">Yes</Button>
                    </Modal.Footer> */}
                </Modal>
            </>
        );
    }
};

import { toTitleCase } from '../../Helpers/Capitalization';
import { setTransactionFilterData } from '../../Redux/Actions/MoneyLink';

class MoneyLinkView extends React.Component {
    constructor(props) {
        super(props);
        let default_yr = StorageData.getDefaultFinancialYear();
        let financial_institute_id = StorageData.getDefaultFI();
        this.props.dispatch(setAppilcationLoder(true));
        this.state = {
            is_search_applied: false,
            transaction_list: [],
            modified_by_list: [],
            transaction_count: {
                not_ready: 0,
                processed: 0,
                ready: 0,
                synced: 0,
                total: 0
            },

            search: "",           // --------> Ram
            searchApplied: false, // --------> Ram

            has_next: false,
            has_previous: false,

            total_pages: 0,
            total_count: 0,
            per_page_items: this.props.per_page_items,
            //page_no: this.props.page_no,
            page_no: 1,
            year_type: default_yr.year_type,
            start_date: default_yr.year_type ? default_yr.start_date : "",
            end_date: default_yr.year_type ? default_yr.end_date : "",
            closing_balance: null,
            gap_count:0,
            gap_date:null,

            filter_by: this.props.filter_by || 0,
            // filter_card: window.location.href.includes('/bulk/') ? 2 : 0, // for bulk
            filter_card: window.location.href.includes('/bulk/') ? 3 : 0, // for bulk

            search: "",
            sort_by: 1,
            sort_order: 1,
            financial_institute: financial_institute_id || null,
            recommendation_meta: {},
            save_and_nexts: false,
            bulk_entry_limit: 0,
            cr_dr_type: 0,
            default_cards: StorageData.getDefaultMoneyLinkCards() || {
                accounting_card: 3,
                synched_card: 5,
                linking_card: 7
            },
            financial_institute_list: [],
            date_type: StorageData.getDateType(),
        }
        this.resetBulkForm = React.createRef();
        this.child = React.createRef();
    }

    componentWillMount() {
        this.filterBySnapId()
    }

    onCardSelected = (task_card_filter) => {
        let default_cards = this.state.default_cards;

        if (task_card_filter == 3 || task_card_filter == 4) {
            default_cards.accounting_card = task_card_filter;
        } else if (task_card_filter == 5 || task_card_filter == 6) {
            default_cards.synched_card = task_card_filter;
        } else if (task_card_filter == 7 || task_card_filter == 8) {
            default_cards.linking_card = task_card_filter;
        }

        StorageData.setDefaultMoneyLinkCards(default_cards)

        this.setState({
            default_cards: default_cards,
            filter_card: task_card_filter,
            page_no: 1
        }, () => this.getTransactionList())
        this.props.dispatch(setMoneyLinkCardFilter(task_card_filter))
    }

    financialYearChanged = async (year_type, start_date, end_date) => {
        if(year_type == 6 && start_date == "" && end_date == ""){
            this.setState({
                year_type,
                start_date:'',
                end_date:''
            })
        }else{
            this.setState({
                year_type,
                start_date,
                end_date,
                page_no: 1
            }, () => {
                this.getTransactionList();
                // this.getTransactionWidgetCount();
            })
        }
        
        let financial_year = StorageData.getFinancialYear();

        if (financial_year) {
            let financial_year_name = financial_year.find((item) => item.id == year_type).name
            console.log(financial_year_name)
            this.props.dispatch(setFinancialYearName(financial_year_name))
        }
        if (year_type == 6) {
            this.props.dispatch(setFilterDateRange(start_date, end_date))
        }
    }

    filterBySnapId = () => {
        let snap_id = JSON.parse(localStorage.getItem('snapshot_id'))
        if (!!snap_id) {
            localStorage.removeItem("financial_institute");
            this.setState({ financial_institute: null }, () => {
                this.props.dispatch(setFinancialInstituteName(null, null, null))
                let filterData = {
                    snapshot_id: parseInt(snap_id)
                }
                this.props.dispatch(setTransactionFilterData(filterData));
                this.child.toggleFilter();
                this.applyFilter("apply");
                localStorage.removeItem("snapshot_id");
            })
        }
    }

    searchFilter = async (value) => {
        if (await this.checkBulkLedgerFlag()) {
            return true;
        }
        this.setState({
            search: value, page_no: 1
        }, () => this.getTransactionList(1))
    }

    financialInstituteChanged = async (value) => {
        this.setState({ financial_institute: value, page_no: 1 }, () => {
            this.getTransactionList();
            // this.getTransactionWidgetCount();
        })
    }

    makeRemoveStar = (transaction_id, changed_star) => {
        let transaction_list = this.state.transaction_list;
        let index = transaction_list.findIndex(transaction => transaction.id == transaction_id);
        let transaction = transaction_list[index];
        transaction.is_star = changed_star;
        transaction_list[index] = transaction;
        this.setState({
            transaction_list
        })
    }

    resetSelection = () => {
        this.bulklist.resetSelection();
    }

    checkBulkLedgerFlag = async () => {
        //checking to reset selected transaction for bulk ledger
        if (this.props.bulk_transaction_list.length) {

            //const result = await confirm("This action will clear the selection. Do you want to continue?", options);
            if (await confirm("This action will clear the selection. Do you want to continue?", options
            )) {
                //resetSelection
                this.bulklist.resetSelection();
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    changeSyncLedgerStatus = (transaction_ids) => {
        let transaction_list = this.state.transaction_list.map(trans => {
            if (transaction_ids.includes(trans.id)) {
                trans.status = 3;
            }
            return trans;
        });
        this.setState({ transaction_list });
        // this.getTransactionWidgetCount();
        this.getTransactionList();
    }

    getTransactionList = (filter_by = this.state.filter_by, is_export = 0) => {
        if (is_export == 0) {
            this.setState({ transaction_list: [] })
        }

        if (filter_by == 1) {
            this.setState({ is_search_applied: true })
        } else {
            this.setState({ is_search_applied: false })
        }
        this.setState({
            filter_by: filter_by,
            transaction_list: []
        })
        //this.props.dispatch(setFilterBy(filter_by))
        this.props.dispatch(setTransactionLoadingStatus(true));
        this.props.dispatch(setAppilcationLoder(true));
        this.props.dispatch(setFilterCard(this.state.filter_card));

        let filter_data = {
            "per_page_items": this.state.per_page_items,
            "page_no": this.state.page_no,
            "date_filter": {
                "start_date": changeDateFormate(this.state.start_date),
                "end_date": changeDateFormate(this.state.end_date),
                "date_type": this.state.year_type == 6 ? parseInt(this.state.date_type) : parseInt(1)
            },
            "filter_card": this.state.filter_card,
            "filter": filter_by,
            "search": this.state.search,
            "filter_box": {
                "type": !!this.props.type ? parseInt(this.props.type) : null,
                "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
                "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
                "modified_by": !!this.props.modified_by ? this.props.modified_by : null,
                "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null,
                "trans_id": !!this.props.transaction_id ?
                    parseInt(this.props.transaction_id)
                    // this.props.transaction_id.slice(2, this.props.transaction_id.length)
                    : null,
                "voucher_status": this.props.voucher_status !== '' ? parseInt(this.props.voucher_status) : null,
                "is_evidence_linked": this.props.is_evidence_linked !== '' ? parseInt(this.props.is_evidence_linked) : null,
                "is_personal": this.props.is_personal_filter !== '' ? parseInt(this.props.is_personal_filter) : null,
                "snapshot_id": !!this.props.snapshot_id ? parseInt(this.props.snapshot_id) : null,
                "messages": this.props.messages !== '' ? parseInt(this.props.messages) : null,

            },
            "is_export": is_export,
            "sort_by": this.state.sort_by,
            "sort_order": this.state.sort_order,
            "financial_institute": this.state.financial_institute,
        };
        if (!!this.state.cr_dr_type)
            filter_data.type = this.state.cr_dr_type;


        MoneyLinkService.getTransactionList(filter_data).then(
            (data) => {
                //alert(JSON.stringify(filter_data));
                this.props.dispatch(setTransactionLoadingStatus(false));
                this.props.dispatch(setAppilcationLoder(false));

                //START - EXPORT LIST CODE
                if (is_export == 1) {
                    //check if response is null i.e if byteLength is or less than 5
                    if (data.byteLength > 5) {
                        var dataa = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        let url = window.URL.createObjectURL(dataa);
                        let a = document.createElement('a');
                        a.href = url;
                        let accNumStr = this.props.account_number != '' ? '_XX' + this.props.account_number.slice(-4) : ''
                        let entityName = toTitleCase(this.props.entity_name)
                        a.download = "Buktec_" + entityName + "_LINK_" + this.props.bank_name + accNumStr + "_" + dateWithMonthName(this.state.start_date) + "-" + dateWithMonthName(this.state.end_date) + ".xlsx";
                        a.click();

                        this.refreshItem()
                    } else {
                        toast.error("No transactions found");
                    }
                }
                //END - EXPORT LIST CODE

                if (data.response_code == 200) {

                    let newList = data.result.transactions.map((item) => {
                        return (
                            { ...item, "financial_institute": item.financial_institute + " " + (showAliasAcctNo(item.is_credit_card, item.account_number)) }
                        )
                    })

                    this.setState({
                        transaction_list: newList,
                        transaction_count: data.result.transaction_counts,
                        per_page_items: data.result.per_page_items,
                        bulk_entry_limit: data.result.bulk_entry_limit,
                        has_next: data.result.has_next,
                        has_previous: data.result.has_previous,
                        page_no: data.result.page_no,
                        total_count: data.result.total_count,
                        total_pages: data.result.total_pages,
                        closing_balance: data.result.closing_balance,
                        gap_count:data.result.gap_count,
                        gap_date:data.result.gap_date,

                    }, () => {
                        if (this.state.save_and_nexts && data.result.transactions.length > 0) {
                            //this.props.history.push('/money-link/' + data.result.transactions[0].id)
                            this.props.history.push({
                                pathname: '/money-link/' + data.result.transactions[0].id,
                                state: {
                                    save_and_next: true
                                }
                            });
                        }
                    });
                } else {
                    toast.error(data.message);
                }
            });
    }

    getTransactionWidgetCount = () => {
        let data = {
            "start_date": changeDateFormate(this.state.start_date),
            "end_date": changeDateFormate(this.state.end_date),
            "financial_institute": this.state.financial_institute || null
        }
        MoneyLinkService.getTransactionCount(data).then(
            (data) => {
                if (data.response_code == 200) {
                    this.setState({
                        transaction_count: data.result.transaction_count,
                    });
                } else {
                    toast.error(data.message);
                }
            });
    }


    handlePageChange = (page, sizePerPage) => {
        this.setState({
            page_no: page
        }, () => this.getTransactionList())
    }

    onSortClick = async (sort_order, sort_by) => {
        if (await this.checkBulkLedgerFlag()) {
            return true;
        }
        this.setState({
            sort_order,
            sort_by,
            page_no: 1
        }, () => this.getTransactionList())
    }

    refreshItem = async () => {
        if (await this.checkBulkLedgerFlag()) {
            return true;
        }
        this.getTransactionList();
        // this.getTransactionWidgetCount();
        this.getRecommendationMeta();
    }

    handleTabChange = async (value) => {
        this.setState({ page_no: 1 })
        if (await this.checkBulkLedgerFlag()) {
            return true;
        }
        if (value == 0) {
            //as when come back from bulk to single then show total card selected
            if (this.state.cr_dr_type == 1 || this.state.cr_dr_type == 2) {
                this.setState({
                    filter_card: 0,
                }, () => {
                    this.props.dispatch(setMoneyLinkCardFilter(0))
                })
            }

            this.setState({ cr_dr_type: 0 })

            this.getTransactionList();
            // this.getTransactionWidgetCount();
            this.props.history.push('/money-link')
        }else if(value == 3){

            if (this.state.cr_dr_type == 1 || this.state.cr_dr_type == 2) {
                this.setState({
                    filter_card: 0,
                }, () => {
                    this.props.dispatch(setMoneyLinkCardFilter(0))
                })
            }

            this.setState({ cr_dr_type: 0 })
            this.getTransactionList();
            this.props.history.push('/money-link/transaction-reset')
        }
         else {

            if (!this.state.financial_institute) {
                toast.error("Please select single financial institute");
            } else {

                await oldconfirm({
                    confirmation: "Kindly note that Bulk Entry shall mark the selected Draft or Ready transactions as Ready and Accounted",
                    options: { type: "alert" }
                })

                //START - New chnages- as now we are showing only pending card selected by default
                let default_cards = this.state.default_cards;
                default_cards.accounting_card = 3;
                StorageData.setDefaultMoneyLinkCards(default_cards)
                this.setState({
                    default_cards: default_cards,
                    filter_card: 3,
                })
                this.props.dispatch(setMoneyLinkCardFilter(3))
                //END - New chnages

                this.setState({ cr_dr_type: value }, () => {
                    this.getTransactionList();
                    this.props.history.push('/money-link/bulk/' + value);
                });
            
        }
    }
        // this.props.dispatch(setCrDrType(value));
    }

    // onCardSelected = async (card_filter) => {
    //     this.setState({
    //         filter_card: card_filter,
    //         page_no: 1
    //     }, () => this.getTransactionList())
    //     // this.getTransactionWidgetCount();
    //     this.props.dispatch(setFilterCard(card_filter))
    // }

    freshRecommendation = () => {
        MoneyLinkService.initiateFreshRecommendation().then(
            (data) => {
                if (data.response_code == 200) {
                    toast.success(data.message);
                    this.setState(prevState => ({
                        recommendation_meta: {
                            ...prevState.recommendation_meta,
                            initiate_flag: 0
                        }
                    }))
                } else {
                    toast.error(data.message);
                }
            });
    }

    getTransactionInitialData = () => {
        MoneyLinkService.getInitialData().then(
            (data) => {
                if (data.response_code == 200) {
                    //remove inactive banks from list
                    let dataList = data.result.financial_institutes.filter((item) => {
                        if (item.is_active === 1) {
                            return item
                        }
                    })

                    let newList = dataList.map((item) => {
                        return (
                            { ...item, "view_Name": item.bank_name + " " + (showAliasAcctNo(item.is_credit_card, item.account_number)) }
                        )
                    })

                    let firstItem = {
                        account_number: null,
                        bank_name: null,
                        branch: null,
                        city_name: null,
                        id: 0,
                        ifsc_code: "SBIN00059677",
                        view_Name: "All"
                    }

                    newList.unshift(firstItem)
                    this.setState({
                        financial_institute_list: newList
                    });

                    if (dataList.length == 1) {
                        let fi_found = newList.find((item) => item.id == this.state.financial_institute)
                        let selected_id = this.state.financial_institute || newList[1].id;

                        if (!fi_found) {
                            selected_id = newList[1].id;
                        }

                        StorageData.setDefaultFI(selected_id);
                        let name = newList.find(x => x.id == selected_id).bank_name;
                        let account_number = newList.find(x => x.id == selected_id).account_number;
                        this.props.dispatch(setFinancialInstituteName(name, selected_id, account_number));
                        this.setState({ financial_institute: selected_id })

                    }
                    else {

                        if (dataList.length != 0) {
                            if (this.state.financial_institute == null) {
                                this.financialInstituteChanged(null)
                                this.props.dispatch(setFinancialInstituteName(null, null, null))
                                StorageData.setDefaultFI(null);
                                this.setState({ financial_institute: null })
                            }
                            else {

                                let fi_found = newList.find((item) => item.id == this.state.financial_institute)
                                let selected_id = this.state.financial_institute || newList[1].id;

                                if (!fi_found) {
                                    selected_id = newList[1].id;
                                }

                                StorageData.setDefaultFI(selected_id);
                                let name = newList.find(x => x.id == selected_id).bank_name;
                                let account_number = newList.find(x => x.id == selected_id).account_number;
                                this.props.dispatch(setFinancialInstituteName(name, selected_id, account_number));
                                this.setState({ financial_institute: selected_id })

                            }

                        } else {
                            this.financialInstituteChanged(null)
                            this.props.dispatch(setFinancialInstituteName(null, null, null))
                            StorageData.setDefaultFI(null);
                            this.setState({ financial_institute: null })
                        }
                    }



                    this.getTransactionList(this.state.filter_by);

                    this.setState({ modified_by_list: data.result.modified_by })
                } else {
                    toast.error(data.message);
                }
            });
    }

    applyFilter = async (type) => {
        if (await this.checkBulkLedgerFlag()) {
            return true;
        }
        this.setState({
            page_no: 1
        }, () => {
            type == "apply" ? this.getTransactionList(2) : this.getTransactionList(0);
        })
    }

    componentWillReceiveProps(nextProps) {
        if ((this.props.location.pathname == '/money-link/bulk/1' || this.props.location.pathname == '/money-link/bulk/2' || this.props.location.pathname == '/money-link/bulk/create') && nextProps.location.pathname == '/money-link') {
            this.setState({
                page_no: 1,
                cr_dr_type: 0
            }, () => {
                this.props.dispatch(setBulkTransactionList([]))
                this.getTransactionList()
                // this.getTransactionWidgetCount()
            })
        }

        // const idPattern = /\/money-link\/\d+/;
        // if(idPattern.test(this.props.location.pathname) && nextProps.location.pathname == '/money-link'){
        //     console.log("inside component rece",this.state.page_no)
        //     this.getTransactionList()
        // }
    }

    componentDidMount() {
        let cr_dr_type = 0;
        if (this.props.location.pathname == '/money-link/bulk/1') {
            cr_dr_type = 1;
        } else if (this.props.location.pathname == '/money-link/bulk/2') {
            cr_dr_type = 2;
        }

        this.setState({ cr_dr_type }, () => {
            if (this.state.start_date != "") {
                let default_yr = StorageData.getFinancialYear();
                let name = default_yr ? default_yr.find(x => x.id == this.state.year_type).name : "";
                this.props.dispatch(setFinancialYearName(name))
                //this.getTransactionList(this.state.filter_by);

                 //custom date range is available .pushing date to filter date range
                let yr = default_yr.filter(x => x.id == this.state.year_type)
                console.log(yr)
        
                if(yr.length > 0){
                    if(yr[0].id == 6){
                        console.log("here")
                        this.props.dispatch(setFilterDateRange((yr[0]).start_date,yr[0].end_date))
                    }
                  }
            }
            this.getTransactionInitialData()

            this.getRecommendationMeta()

            this.props.dispatch(setBulkTransactionList([]));
        })
        let filter_data = {
            transaction_id: '',
            type: '',
            from_amount: '',
            to_amount: '',
            modified_by: '',
            is_star: '',
            voucher_status: '',
            is_evidence_linked: '',
            is_personal_filter: '',
            snapshot_id: '',
            messages: ''
        }
        this.props.dispatch(setTransactionFilterData(filter_data));
    }

    getRecommendationMeta() {

        MoneyLinkService.getRecommendationMeta().then(
            (data) => {
                if (data.response_code == 200) {
                    this.setState({ recommendation_meta: data.result })
                } else {
                    toast.error(data.message);
                }
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.page_no != this.props.page_no) {
            this.setState({
                page_no: this.props.page_no
            }, () => {
                this.getTransactionList()
                // this.getTransactionWidgetCount()
            })
        }
    }

    updateList = (transaction_id, entry_status, accounting_status, card_filter, is_personal, evidences, save_and_nexts, old_entry_status, old_accounting_status,old_evidences) => {
        let list = [...this.state.transaction_list]
        let index = this.state.transaction_list.findIndex(transaction => transaction.id == transaction_id)

        if (index !== -1) {
            list[index].status = accounting_status > 3 ? parseInt(accounting_status) : parseInt(entry_status)
            list[index].is_personal = parseInt(is_personal)
            list[index].has_evidence = evidences > 0 ? 1 : 0



            let transaction_counts = this.state.transaction_count;
            console.log("old data log",)

            if((old_evidences.length == 0) && (evidences > 0)){
                console.log("count has increased by 1",transaction_counts)
                transaction_counts.linked = (parseInt(transaction_counts.linked + 1))
                transaction_counts.not_linked = (parseInt(transaction_counts.not_linked - 1))
            }

            if((old_evidences.length > 0) && (evidences == 0)){
                console.log("count has decreaed by 1",transaction_counts)
                transaction_counts.linked = (parseInt(transaction_counts.linked - 1))
                transaction_counts.not_linked = (parseInt(transaction_counts.not_linked + 1))
            }

            if (old_entry_status != entry_status) {
                if (old_entry_status == 1) {
                    transaction_counts.draft = (parseInt(transaction_counts.draft) - 1);
                } else if (old_entry_status == 2) {
                    transaction_counts.ready = (parseInt(transaction_counts.ready) - 1);
                }
            }

            if (old_accounting_status != accounting_status) {
                if (old_accounting_status == 3) {
                    transaction_counts.pending = (parseInt(transaction_counts.pending) - 1);
                } else if (old_accounting_status == 4) {
                    transaction_counts.accounted = (parseInt(transaction_counts.accounted) - 1);
                } else if (old_accounting_status == 5) {
                    transaction_counts.synced_by_user = (parseInt(transaction_counts.synced_by_user) - 1);
                }
            }


            //add count
            if (old_entry_status != entry_status) {
                if (entry_status == 1) {
                    transaction_counts.draft = (parseInt(transaction_counts.draft) + 1);
                } else if (entry_status == 2) {
                    transaction_counts.ready = (parseInt(transaction_counts.ready) + 1);
                }
            }

            if (old_accounting_status != accounting_status) {
                if (accounting_status == 3) {
                    transaction_counts.pending = (parseInt(transaction_counts.pending) + 1);
                } else if (accounting_status == 4) {
                    transaction_counts.accounted = (parseInt(transaction_counts.accounted) + 1);
                } else if (accounting_status == 5) {
                    transaction_counts.synced_by_user = (parseInt(transaction_counts.synced_by_user) + 1);
                }
            }
            console.log(transaction_counts)
            this.setState({ transaction_count: transaction_counts })
            this.setState({ transaction_list: list, save_and_nexts })
        } else {
            // while updating ledger it is giving error -- index not found
            // toast.warning("List couldn't updated")
        }

        // if (save_and_nexts) {
        //     let next_trasaction = this.state.transaction_list[index + 1];
        //     if (!!next_trasaction) {
        //         this.props.history.push('/money-link/' + next_trasaction.id)
        //     } else if (this.state.has_next) {
        //         this.setState({ page_no: this.state.page_no + 1 }, () => {
        //             //this.getTransactionList();
        //         })
        //     }
        // }

        //this.onCardSelected(card_filter)
    }

    // --------- Ram --------------

    changeSearchText = (search_text) => {
        this.setState({
            search: search_text,
        })
    }

    changeSearchStatus = (status) => {
        this.setState({
            searchApplied: status
        })
    }

    // ---------- Ram -------------

    dateTypeChanged = (e) => {
        this.setState({ date_type: parseInt(e.target.value) }, () => {
            // if(this.state.start_date && this.state.end_date){
            //     this.getTransactionList();
            // }
            StorageData.setDateType(this.state.date_type)
            if(this.props.filter_start_date_range && this.props.filter_end_date_range){
               
                this.getTransactionList();
            }
           
        })
    }

    updateListStatus = (transaction_id, entry_status, accounting_status, is_personal, evidences) => {
        let list = [...this.state.transaction_list]
        let index = this.state.transaction_list.findIndex(transaction => transaction.id == transaction_id)

        if (index !== -1) {
            list[index].status = accounting_status > 3 ? parseInt(accounting_status) : parseInt(entry_status)
            list[index].is_personal = parseInt(is_personal)
            list[index].has_evidence = evidences > 0 ? 1 : 0;

            this.setState({ transaction_list: list })
            // console.log(list)
        }
    }

    moveToNextPage = (index) => {
        let next_trasaction = this.state.transaction_list[index + 1];
        if (!!next_trasaction) {
            this.props.history.push('/money-link/' + next_trasaction.id)
        } else {
            if (this.state.has_next) {
                this.setState({ page_no: this.state.page_no + 1 }, () => {
                    StorageData.setMoneyLinkPageNo(this.state.page_no)
                    this.props.dispatch(setPageNo(this.state.page_no));
                    this.setState({ save_and_nexts: true }, () => {
                        this.getTransactionList();
                    })
                })
            }
        }
    }

    //for selecting all entries for bulk ledger
    selectAllTransactions = () => {

        this.props.dispatch(setBulkTransactionList([]));
        this.props.dispatch(setTransactionLoadingStatus(true));
        this.props.dispatch(setAppilcationLoder(true));

        let filter_data = {
            "per_page_items": 0,
            "page_no": 0,
            "date_filter": {
                "start_date": changeDateFormate(this.state.start_date),
                "end_date": changeDateFormate(this.state.end_date),
                "date_type": this.state.date_type
            },
            "filter_card": this.state.filter_card,
            "filter": this.state.filter_by,
            "search": this.state.search,
            "filter_box": {
                "type": !!this.props.type ? parseInt(this.props.type) : null,
                "from_amount": !!this.props.from_amount ? this.props.from_amount : null,
                "to_amount": !!this.props.to_amount ? this.props.to_amount : null,
                "modified_by": !!this.props.modified_by ? this.props.modified_by : null,
                "is_star": this.props.is_star !== '' ? parseInt(this.props.is_star) : null,
                "trans_id": !!this.props.transaction_id ? parseInt(this.props.transaction_id) : null,
                "voucher_status": this.props.voucher_status !== '' ? parseInt(this.props.voucher_status) : null,
                "is_evidence_linked": this.props.is_evidence_linked !== '' ? parseInt(this.props.is_evidence_linked) : null,
                "is_personal": this.props.is_personal_filter !== '' ? parseInt(this.props.is_personal_filter) : null,
                "snapshot_id": !!this.props.snapshot_id ? parseInt(this.props.snapshot_id) : null,
                "messages": this.props.messages !== '' ? parseInt(this.props.messages) : null,
            },
            "is_export": 0,
            "sort_by": this.state.sort_by,
            "sort_order": this.state.sort_order,
            "financial_institute": this.state.financial_institute || null,
        };
        if (!!this.state.cr_dr_type)
            filter_data.type = this.state.cr_dr_type;


        MoneyLinkService.getTransactionList(filter_data)
            .then((data) => {
                this.props.dispatch(setTransactionLoadingStatus(false));
                this.props.dispatch(setAppilcationLoder(false));

                if (data.response_code == 200) {

                    let new_list = data.result.transactions.map((item) => {
                        return (
                            { ...item, "financial_institute": item.financial_institute + " " + (showAliasAcctNo(item.is_credit_card, item.account_number)) }
                        )
                    })

                    let selected_items = new_list.map(data => data.id);
                    this.bulklist.selectedTransactions(selected_items)
                    this.props.dispatch(setBulkTransactionList(new_list));

                } else {
                    toast.error(data.message);
                }
            });
    }

    resetSaveAndNext = () => {
        this.setState({ save_and_nexts: false })
    }

    render() {
        console.log("filter date",this.props.filter_start_date_range)
    console.log("filter date",this.props.filter_end_date_range)
        return (
            <div>
                <ScrollPage />
                <Switch>
                    <Redirect from='/mu/:transaction_id?' to="/money-link/:transaction_id?" />
                    <Route path="/money-link"
                        render={() => {
                            return <MoneyLinkList
                                filter_by={this.state.filter_by}
                                is_search_applied={this.state.is_search_applied}
                                searchText={this.state.search}               //---------Ram
                                changeSearchText={this.changeSearchText}     //---------Ram
                                searchApplied={this.state.searchApplied}
                                changeSearchStatus={this.changeSearchStatus}
                                transaction_list={this.state.transaction_list}
                                transaction_count={this.state.transaction_count}
                                total_count={this.state.total_count}
                                per_page_items={this.state.per_page_items}
                                page_no={this.state.page_no}
                                sort_by={this.state.sort_by}
                                sort_order={this.state.sort_order}
                                filter_card={this.state.filter_card}
                                modified_by_list={this.state.modified_by_list}
                                searchFilter={this.searchFilter}
                                makeRemoveStar={this.makeRemoveStar}
                                financialInstituteChanged={this.financialInstituteChanged}
                                onSortClick={this.onSortClick}
                                handlePageChange={this.handlePageChange}
                                financialYearChanged={this.financialYearChanged}
                                refreshItem={this.refreshItem}
                                onCardSelected={this.onCardSelected}
                                applyFilter={this.applyFilter}
                                freshRecommendation={this.freshRecommendation}
                                recommendation_meta={this.state.recommendation_meta}
                                handleTabChange={this.handleTabChange}
                                cr_dr_type={this.state.cr_dr_type}
                                getTransactionList={this.getTransactionList}
                                default_cards={this.state.default_cards}
                                financial_institute_list={this.state.financial_institute_list}
                                onRef={ref => (this.child = ref)}
                                closing_balance={this.state.closing_balance}
                                gap_count={this.state.gap_count}
                                gap_date={this.state.gap_date}
                                dateTypeChanged={this.dateTypeChanged}
                                date_type={this.state.date_type}
                            // filterBySnapId={this.filterBySnapId}
                            />
                        }
                        }
                        exact />
                    <Route path={["/money-link/transaction-reset"]}
                        render={() => {
                            return <TransactionReset
                                filter_by={this.state.filter_by}
                                onRef={ref => (this.bulklist = ref)}
                                is_search_applied={this.state.is_search_applied}
                                searchText={this.state.search}               //---------Ram
                                changeSearchText={this.changeSearchText}     //---------Ram
                                searchApplied={this.state.searchApplied}
                                changeSearchStatus={this.changeSearchStatus}
                                transaction_list={this.state.transaction_list}
                                transaction_count={this.state.transaction_count}
                                total_count={this.state.total_count}
                                per_page_items={this.state.per_page_items}
                                bulk_entry_limit={this.state.bulk_entry_limit}
                                page_no={this.state.page_no}
                                sort_by={this.state.sort_by}
                                sort_order={this.state.sort_order}
                                filter_card={this.state.filter_card}
                                modified_by_list={this.state.modified_by_list}
                                searchFilter={this.searchFilter}
                                makeRemoveStar={this.makeRemoveStar}
                                financialInstituteChanged={this.financialInstituteChanged}
                                onSortClick={this.onSortClick}
                                handlePageChange={this.handlePageChange}
                                financialYearChanged={this.financialYearChanged}
                                refreshItem={this.refreshItem}
                                onCardSelected={this.onCardSelected}
                                checkBulkLedgerFlag={this.checkBulkLedgerFlag}
                                applyFilter={this.applyFilter}
                                freshRecommendation={this.freshRecommendation}
                                recommendation_meta={this.state.recommendation_meta}
                                handleTabChange={this.handleTabChange}
                                //onRef={ref => (this.resetBulkForm = ref)}
                                cr_dr_type={this.state.cr_dr_type}
                                resetSelection={this.resetSelection}
                                default_cards={this.state.default_cards}
                                financial_institute_list={this.state.financial_institute_list}
                                onRefr={ref => (this.child = ref)}
                                dateTypeChanged={this.dateTypeChanged}
                                date_type={this.state.date_type}
                                selectAllTransactions={this.selectAllTransactions}
                            />
                        }
                        }
                        exact />
         <Route path={["/money-link/bulk/1", "/money-link/bulk/2"]}
                        render={() => {
                            return <MoneyLinkBulkList
                                filter_by={this.state.filter_by}
                                onRef={ref => (this.bulklist = ref)}
                                is_search_applied={this.state.is_search_applied}
                                searchText={this.state.search}               //---------Ram
                                changeSearchText={this.changeSearchText}     //---------Ram
                                searchApplied={this.state.searchApplied}
                                changeSearchStatus={this.changeSearchStatus}
                                transaction_list={this.state.transaction_list}
                                transaction_count={this.state.transaction_count}
                                total_count={this.state.total_count}
                                per_page_items={this.state.per_page_items}
                                bulk_entry_limit={this.state.bulk_entry_limit}
                                page_no={this.state.page_no}
                                sort_by={this.state.sort_by}
                                sort_order={this.state.sort_order}
                                filter_card={this.state.filter_card}
                                modified_by_list={this.state.modified_by_list}
                                searchFilter={this.searchFilter}
                                makeRemoveStar={this.makeRemoveStar}
                                financialInstituteChanged={this.financialInstituteChanged}
                                onSortClick={this.onSortClick}
                                handlePageChange={this.handlePageChange}
                                financialYearChanged={this.financialYearChanged}
                                refreshItem={this.refreshItem}
                                onCardSelected={this.onCardSelected}
                                checkBulkLedgerFlag={this.checkBulkLedgerFlag}
                                applyFilter={this.applyFilter}
                                freshRecommendation={this.freshRecommendation}
                                recommendation_meta={this.state.recommendation_meta}
                                handleTabChange={this.handleTabChange}
                                //onRef={ref => (this.resetBulkForm = ref)}
                                cr_dr_type={this.state.cr_dr_type}
                                resetSelection={this.resetSelection}
                                default_cards={this.state.default_cards}
                                financial_institute_list={this.state.financial_institute_list}
                                onRefr={ref => (this.child = ref)}
                                dateTypeChanged={this.dateTypeChanged}
                                date_type={this.state.date_type}
                                selectAllTransactions={this.selectAllTransactions}
                            />
                        }
                        }
                        exact />
                    <Route path="/money-link/bulk/create"
                        render={() => {
                            return <BulkLedgerForm
                                is_search_applied={this.state.is_search_applied}
                                searchText={this.state.search}               //---------Ram
                                changeSearchText={this.changeSearchText}     //---------Ram
                                searchApplied={this.state.searchApplied}
                                changeSearchStatus={this.changeSearchStatus}
                                transaction_list={this.state.transaction_list}
                                transaction_count={this.state.transaction_count}
                                total_count={this.state.total_count}
                                per_page_items={this.state.per_page_items}
                                page_no={this.state.page_no}
                                sort_by={this.state.sort_by}
                                sort_order={this.state.sort_order}
                                filter_card={this.state.filter_card}
                                modified_by_list={this.state.modified_by_list}
                                searchFilter={this.searchFilter}
                                makeRemoveStar={this.makeRemoveStar}
                                financialInstituteChanged={this.financialInstituteChanged}
                                onSortClick={this.onSortClick}
                                handlePageChange={this.handlePageChange}
                                financialYearChanged={this.financialYearChanged}
                                refreshItem={this.refreshItem}
                                onCardSelected={this.onCardSelected}
                                applyFilter={this.applyFilter}
                                freshRecommendation={this.freshRecommendation}
                                recommendation_meta={this.state.recommendation_meta}
                                handleTabChange={this.handleTabChange}
                                changeSyncLedgerStatus={this.changeSyncLedgerStatus}
                                cr_dr_type={this.state.cr_dr_type}
                            />
                        }
                        }
                        exact />
                    {(this.props.features_permissions_list.includes(LINKING_CODE) ||
                        this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) ||
                        this.props.features_permissions_list.includes(VIEW_LINKED_ENTRIES_CODE)) &&

                        <Route path={["/money-link/:transaction_id?", "/mu/:transaction_id?"]}
                            render={() => {
                                return <LinkTransactionView
                                    filter_by={this.state.filter_by}
                                    searchText={this.state.search}               //---------Ram
                                    changeSearchText={this.changeSearchText}     //---------Ram
                                    searchApplied={this.state.searchApplied}
                                    changeSearchStatus={this.changeSearchStatus}
                                    transaction_list={this.state.transaction_list}
                                    per_page_items={this.state.per_page_items}
                                    page_no={this.state.page_no}
                                    has_next={this.state.has_next}
                                    total_pages={this.state.total_pages}
                                    has_previous={this.state.has_previous}
                                    modified_by_list={this.state.modified_by_list}
                                    applyFilter={this.applyFilter}
                                    financial_institute={this.state.financial_institute}
                                    year_type={this.state.year_type}
                                    updateList={this.updateList}
                                    transaction_id={this.props.match.params.transaction_id}
                                    is_search_applied={this.state.is_search_applied}
                                    searchFilter={this.searchFilter}
                                    onCardSelected={this.onCardSelected}
                                    filter_card={this.state.filter_card}
                                    updateListStatus={this.updateListStatus}
                                    moveToNextPage={this.moveToNextPage}
                                    resetSaveAndNext={this.resetSaveAndNext}
                                />
                            }
                            }
                            exact />}

                    <Redirect to="/home" />
                </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const all_value = state.MoneyLink || {};
    const from_amount = all_value.from_amount || '';
    const to_amount = all_value.to_amount || '';
    const modified_by = all_value.modified_by || '';
    const is_star = all_value.is_star || '';
    const voucher_status = all_value.voucher_status || '';
    const transaction_id = all_value.transaction_id || ''
    const type = all_value.type || '';
    const page_no = all_value.page_no || 1;
    const bank_value = all_value.bank_value || null;
    const filter_by = all_value.filter_by || 0;
    const search = all_value.search || "";
    const bulk_transaction_list = all_value.bulk_transaction_list || []

    const is_evidence_linked = all_value.is_evidence_linked || '';
    const is_personal = all_value.is_personal || '';
    const is_personal_filter = all_value.is_personal_filter || '';
    const messages = all_value.messages || '';
    const snapshot_id = all_value.snapshot_id || '';
    const entity_name = state.Session.default_entity_detail.name || {};
    // const account_number = (all_value.bank_value != 0)?all_value.account_number : ''
    // const bank_name = all_value.bank_value != 0?all_value.bank_name :'ALL'
    const account_number = (!!all_value.bank_value) ? all_value.account_number : ''
    const bank_name = (!!all_value.bank_value) ? all_value.bank_name : 'ALL'

    const session_value = state.Session || {};
    const features_permissions_list = session_value.features_permissions_list || [];
    const system_parameter = session_value.system_parameter || {};
    const per_page_items = system_parameter.per_page_items || 0;
    const filter_start_date_range = all_value.filter_start_date|| "";
    const filter_end_date_range = all_value.filter_end_date || "";
  


    return {
        search, filter_by, is_star, from_amount, to_amount, transaction_id, modified_by, type, page_no, features_permissions_list,filter_start_date_range,filter_end_date_range,
        bank_value, bulk_transaction_list, voucher_status, per_page_items, is_evidence_linked, is_personal, snapshot_id, entity_name, account_number, bank_name, is_personal_filter, messages
    };
}

export default connect(mapStateToProps)(MoneyLinkView);
