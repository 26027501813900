import axios from 'axios';
import Header from '../Header';
import { API_URL } from '../../Utils/Config';

class DocboxService {

	deleteFileFolder(id) {
		return axios
			.delete(API_URL + "docbox/" + id, { headers: Header.entityHeader() })
			.then((response) => {
				return response.data;
			}).catch((error) => {
				return error;
			});
	}

	getDocboxList(data) {
		return axios.post(API_URL + 'docbox-list', data, { headers: Header.entityHeader() })
			.then((response) => {
			return response.data;
			}).catch((error) => {
			return error;
		});
	}

	createFileFolder(data) {
		return axios.post(API_URL + 'docbox', data, { headers: Header.entityHeader() })
			.then((response) => {
			return response.data;
			}).catch((error) => {
			return error;
		});
	}

	makeRemoveStar(data) {
		return axios.put(API_URL + 'docbox-star', data, { headers: Header.entityHeader() })
		.then((response) => {
			return response.data;
		}).catch((error) => {
			return error;
		});
	}

	abc(API_URL) {
		return axios.get(API_URL)
			.then((response) => {
			return response.data;
			}).catch((error) => {
			return error;
		});
	}
}

export default new DocboxService();