import React from 'react'
import { Accordion, Card, Form, Table, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import VoucherCardView from '../../Components/BoilerPlate/VoucherCardView';
import { changeDateFormate, DatePickerFormate, displayDateTimeFormat, changeDateTimeFormat } from '../../Helpers/DateFormat';
import { setTempVoucherList } from '../../Redux/Actions/Snapshot';
//import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap'; 
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

class SnapshotLedger extends React.Component {
  constructor(props) {
    super(props);

    //this.btnRef= React.createRef();

    this.props.temp_snap_voucher_list.forEach((voucher, indx) => {
      this[`${indx}__vouchercardref`] = React.createRef()
    });

    this.state = {
      voucher_type_guid: '',
      voucher_creation_date: !!this.props.transactionDate ? new Date(DatePickerFormate(this.props.transactionDate)) : "",
      is_permission_granted: this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) || true,

    }
  }

  componentDidUpdate = () => {
    if (this.props.transactionDate != undefined && this.state.voucher_creation_date == null) {
      setTimeout(() => {
        this.setState({ voucher_creation_date: new Date(DatePickerFormate(this.props.transactionDate)) })
      }, 600);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.transactionDate != nextProps.voucher_creation_date) {
      if (this.props.transactionDate != undefined && this.state.voucher_creation_date == null) {
        setTimeout(() => {
          this.setState({ voucher_creation_date: new Date(DatePickerFormate(this.props.transactionDate)) })
        }, 600);
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setTempVoucherList([]))
  }

  handleChange = (e) => {
    this.setState({ voucher_type_guid: e.target.value })
    this.props.isFormDirty()
  }

  setDate = (date, key) => {
    this.setState({ [key]: date })
    this.props.isFormDirty()
  }

  editVoucherDate = (date, voucher_index) => {
    let temp_snap_voucher_list = this.props.temp_snap_voucher_list;
    temp_snap_voucher_list[voucher_index].voucher_creation_date = changeDateFormate(date)
    // temp_snap_voucher_list[voucher_index].ledgers.forEach((ledger, index) => {
    //   ledger.voucher_creation_date = changeDateFormate(date)
    // })

    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    this.props.isFormDirty()
  }
  editVouchertype = (voucher_details, voucher_index,type=null) => {
    console.log("edit voucher ",voucher_details,voucher_index)
    let temp_snap_voucher_list = this.props.temp_snap_voucher_list;
        temp_snap_voucher_list[voucher_index].voucher_type_guid = voucher_details.guid
        temp_snap_voucher_list[voucher_index].parent_voucher_type_id = voucher_details.parent_voucher_type_id
        temp_snap_voucher_list[voucher_index].voucher_type_name = voucher_details.name
        temp_snap_voucher_list[voucher_index].voucher_type_id = voucher_details.id

    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    if (type != 'first_time'){
      this.props.isFormDirty()
    }
      
  }
  

  validateVoucherType = () => {
    if (this.state.voucher_type_guid !== "" && (this.state.voucher_creation_date != "" && this.state.voucher_creation_date != 'Invalid Date')) {
      return true;
    } else {
      toast.error('Please select voucher type & date')
      return false;
    }
  }

  removeVoucher = (voucher_id, index) => {
    let temp_snap_voucher_list = [...this.props.temp_snap_voucher_list];
    if (voucher_id !== 0) {
      let temp_index = temp_snap_voucher_list.findIndex(voucher => voucher.voucher_id == voucher_id);
      if (temp_index !== -1) {
        temp_snap_voucher_list.splice(temp_index, 1);
      } else {
      }
    } else {
      temp_snap_voucher_list.splice(index, 1);
    }
    this.props.isFormDirty()
    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    if(temp_snap_voucher_list.length == 0){
        this.props.checkForAccountedState(false)
    }
    
  }

  addVoucher = async () => {
    if (this.validateVoucherType()) {
      let temp_snap_voucher_list = [...this.props.temp_snap_voucher_list];

      let voucher_details = this.props.voucherTypeList.filter((voucher) => voucher.guid == this.state.voucher_type_guid)
  
      let temp_voucher = {
        "accounting_narration": this.props.narration,
        "parent_voucher_type_id":voucher_details[0].parent_voucher_type_id,
        "voucher_type_guid":voucher_details[0].guid,
        "voucher_type_name":voucher_details[0].name,
        "voucher_type_id":voucher_details[0].id,
        "voucher_creation_date": changeDateFormate(this.state.voucher_creation_date),
        "total_credit_amount": "",
        "total_debit_amount": "",
        "voucher_id": 0,
        "ledgers": [],
        "sync_status": 0,
        "sync_datetime": changeDateTimeFormat(new Date())
      }

      temp_snap_voucher_list.push(temp_voucher)
      await this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))

      this.props.isFormDirty()
      this.setState({ voucher_type_guid: '', voucher_creation_date: '' })
      this.scrollToBottom();
      this.props.checkForAccountedState(true)
    }
  }

  updateVoucher = (temp_ledger_list, voucher_id, voucher_index, type) => { //alert('22'+type)


    let temp_snap_voucher_list = this.props.temp_snap_voucher_list;
    console.log(temp_snap_voucher_list.length)

    //console.log(voucher_id)
    if (temp_snap_voucher_list.length != 0) {
      if (voucher_id !== 0) {
        let index = temp_snap_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
        if (index != -1)
          temp_snap_voucher_list[index].ledgers = temp_ledger_list
      } else {
        temp_snap_voucher_list[voucher_index].ledgers = temp_ledger_list
      }
    }
    //console.log(temp_snap_voucher_list)
    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    this.setState({ voucher_type_guid: '' })

    if (type != 'first_time')
      this.props.isFormDirty()
  }

  updateTotal = (cr_total, dr_total, voucher_id, voucher_index, type) => { //alert('44'+type)
    let temp_snap_voucher_list = this.props.temp_snap_voucher_list;

    if (temp_snap_voucher_list.length != 0) {
      if (voucher_id !== 0) {
        let index = temp_snap_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
        if (index != -1) {
          temp_snap_voucher_list[index].total_debit_amount = dr_total.toFixed(2);
          temp_snap_voucher_list[index].total_credit_amount = cr_total.toFixed(2);
        }
      } else {
        temp_snap_voucher_list[voucher_index].total_debit_amount = dr_total.toFixed(2);
        temp_snap_voucher_list[voucher_index].total_credit_amount = cr_total.toFixed(2);
      }
    }

    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    this.setState({ voucher_type_guid: '' })

    if (type != 'first_time')
      this.props.isFormDirty()
  }

  updateAccountingNarration = (narration, voucher_id, voucher_index) => {
    let temp_snap_voucher_list = this.props.temp_snap_voucher_list;
    if (voucher_id !== 0) {
      let index = temp_snap_voucher_list.findIndex((voucher) => voucher.voucher_id == voucher_id)
      temp_snap_voucher_list[index].accounting_narration = narration
    } else {
      temp_snap_voucher_list[voucher_index].accounting_narration = narration
    }
    this.props.dispatch(setTempVoucherList(temp_snap_voucher_list))
    this.setState({ voucher_type_guid: '' })
    this.props.isFormDirty()
  }

  getLedgerList = (ledgers) => {
    return ledgers.map((ledger, index) => {
      delete ledger.ledger_name
      return ledger
    })
  }

  handleTabClick = () => {
    // let testElements = document.getElementsByClassName('collapse');
    // if (testElements[3].classList.length === 2) {
    // }else{
    //   document.getElementById("narattionTab").classList.remove('show');
    //   document.getElementById("docboxTab").classList.remove('show');
    //   document.getElementById("searchTab").classList.remove('show');

    // }
  }

  position = (obj) => {
    let currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop - 200];
    }
  }

  scrollToBottom = () => {
    // fix for BU1-I248
    setTimeout(() => {
      window.scrollTo({ top: this.position(document.getElementById(`vouchercardref_${(this.props.temp_snap_voucher_list.length - 1)}`)), behavior: 'smooth' })
    }, 500);
  }

  getSyncStatus = (status) => {
    if (status == 0) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Not Synced</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 gray-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 1) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Send to Sync</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 blue-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 2) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Synced</span></Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    } else if (status == 3) {
      return <OverlayTrigger overlay={<Tooltip id="tooltip-disabled"><span>Sync Error</span><br />Error: {this.props.ledgerNameList.sync_error}</Tooltip>}>
        <span class="icon-personal-icon ledger-trs-icon"><span class="path1 red-icon"></span><span class="path2"></span><span class="path3"></span></span>
      </OverlayTrigger>
    }
  }

  render() {
    console.log("voucher type",this.state.voucher_type_guid)
    console.log("temp",this.props.temp_snap_voucher_list)
    const is_permission_granted = this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE) || true;
    const read_only = this.props.read_only;
    const voucher_creation_date = this.state.voucher_creation_date
    //console.log(this.props.temp_snap_voucher_list)
    return (
      <>
        {this.props.ledgerNameList.last_updated_at === null ?
          <p className="ledger-bar-import-text">Please import ledger to use this section</p>
          :
          <p className="ledger-icon-bar">
            <span className="ledger-left-bar">
              {/* <OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
                <button class="btn refresh-icon-new-round blink-green-btn mr-2" onClick={() => { this.props.getLedgerNameList(); this.props.getCostcenterCategory(); }}>
                  <span class="icon-refresh"></span>
                </button>
              </OverlayTrigger> */}

              <OverlayTrigger overlay={<Tooltip>Refresh Ledgers</Tooltip>}>
                <span className={"icon-refresh-iconn refresh-licon blink-green-btn " + (this.props.read_only ? "cnotallowed disable-opacity" : "")} onClick={() => {
                  if(is_permission_granted && !read_only){
                    this.props.getLedgerNameList();
                    this.props.getCostcenterCategory();
                    this.props.getVoucherTypeList();
                  }
                     }} ><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></span>
              </OverlayTrigger>


              <OverlayTrigger overlay={<Tooltip>Synced</Tooltip>}>
                {/* <button class="btn refresh-icon-new-round mr-2">
                  <span class="icon-check white-icon"></span>
                </button> */}
                <span class="icon-personal-icon ledger-trs-icon"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
              </OverlayTrigger>

              {this.props.ledgerNameList.last_updated_at && 'Last Sync: ' + (this.props.ledgerNameList.last_updated_at)}
            </span>

            <span className='ledger-right-bar'>
              {/* <OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
                <button onClick={() => this.props.getSnapshotLedgerRecommendation()} class="btn refresh-icon-new-round"><span class="icon-invoice1 white-icon"></span></button>
              </OverlayTrigger> */}
              <OverlayTrigger overlay={<Tooltip className="">Refresh Recommendation</Tooltip>}>
                <span class={"icon-Group-4507 refresh-recom-icon ledger-trs-icon mr-0" + (this.props.read_only ? "cnotallowed disable-opacity" : "")} onClick={() => {if(is_permission_granted && !read_only){this.props.getSnapshotLedgerRecommendation()}}}><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></span>
              </OverlayTrigger>
            </span>
          </p>
        }

        {/* <div className="" style={{ overflow: 'inherit', maxHeight: 'inherit' }}> */}
        {this.props.temp_snap_voucher_list.length === 0 ?
          <div className="no-voucher">
            <p>No Voucher</p>
          </div> :
          this.props.temp_snap_voucher_list.map((voucher, index) => {
            return <div id={`vouchercardref_${index}`}>
              <VoucherCardView
                ref={this[`${index}_vouchercardref`]}
                key={index + voucher.voucher_id}
                index={index}
                is_leder_recommended={!!voucher.is_leder_recommended}
                voucher_type_guid={voucher.voucher_type_guid}
                parent_voucher_type_id = {voucher.parent_voucher_type_id}
                voucher_name = {voucher.voucher_type_name}
                accounting_narration={voucher.accounting_narration}
                ledgers={this.getLedgerList(voucher.ledgers)}
                total_credit_amount={voucher.total_credit_amount}
                total_debit_amount={voucher.total_debit_amount}
                voucher_creation_date={voucher.voucher_creation_date}
                voucher_id={voucher.voucher_id}
                removeVoucher={this.removeVoucher}
                ledgerNameList={this.props.ledgerNameList}
                updateVoucher={this.updateVoucher}
                updateTotal={this.updateTotal}
                updateAccountingNarration={this.updateAccountingNarration}
                removeLedger={this.props.removeLedger}
                is_valid_voucher={this.props.is_valid_voucher}
                is_valid_ledger={this.props.is_valid_ledger}
                read_only={this.props.read_only}
                transactionAmount={this.props.transactionAmount}
                transactionType={this.props.transactionType}
                voucherNos={this.props.temp_snap_voucher_list.length}
                editVoucherDate={this.editVoucherDate}
                editVouchertype = {this.editVouchertype}
                submit_click={this.props.submit_click}
                narration={this.props.narration}
                sync_status={voucher.sync_status}
                sync_datetime={voucher.sync_datetime}
                sync_error={voucher.sync_error}
                voucher={voucher}
                isFormDirty={this.props.isFormDirty}
                amount_round_off = {this.props.amount_round_off}
                voucherTypeList = {this.props.voucherTypeList}
                vaild_voucher = {this.props.vaild_voucher}
              />
            </div>
          })}
        {/* <div ref={this.btnRef} /> */}
        {/* </div> */}


        {(is_permission_granted && !read_only) &&
          <div className="row">
            {/* <div class="col-xl-3 col-lg-4"></div> */}
            <div className="col-xl-12 col-lg-12 right-form-sec ledger-type-date">
              {/* <Form>
                <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                  <Form.Control as="select" disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} name="voucher_type" value={this.state.voucher_type}
                    onChange={this.handleChange} >
                    <option selected value="">Voucher Type</option>
                    <option value={1}>Contra</option>
                    <option value={4}>Journal</option>
                    <option value={2}>Payment</option>
                    <option value={6}>Purchase</option>
                    <option value={3}>Receipt</option>
                    <option value={5}>Sales</option>
                  </Form.Control>
                </Form.Group>
              </Form> */}
               {(this.props.voucherTypeList.length == 0) && <small className="error_right">Please sync with tally to get Voucher Types</small>}
             <Form>
                <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0 date-width-130">
                  <Form.Control as="select" disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} name="voucher_type" value={this.state.voucher_type_guid}
                    onChange={this.handleChange} >
                    <option selected value="">Voucher Type</option>
                    {
														this.props.voucherTypeList.map((value,index)=>{
															return (
																<option key={index} value={value.guid}>
															  {value.name}
																</option>
															  );
														})
													}
                  </Form.Control>
                </Form.Group>
              </Form>

              <div className="form-group mb-0 date-width-130">
                <DatePicker
                  disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false}
                  dateFormat={DatePickerFormate()}
                  onChange={date => this.setDate(date, "voucher_creation_date")}
                  className="form-control datepicker-icon"
                  selected={Date.parse(voucher_creation_date)}
                  autoComplete="off"
                  placeholder="Voucher Date"
                  name="voucher_creation_date"
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown />
                {/* <span className="icon-calendar"></span> */}
              </div>

              {/* <button disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false} type="button" className="btn save-btn s-bold greenbtn addbtn mt-lg-0 mt-md-2 d-flex align-items-center float-right"
              onClick={this.addVoucher}
            >Add</button> */}

              <Button variant="success apply_btn_new"
                // className={"btn save-btn s-bold greenbtn pull-right " + (this.state.loading ? "position-reltv" : "")}
                disabled={(this.props.ledgerNameList.ledger_list !== undefined && this.props.ledgerNameList.ledger_list.length === 0) ? true : false}
                onClick={this.addVoucher}
              >Add</Button>
            </div>
          </div>
        }

      </>
    )
  }
}

function mapStateToProps(state) {
  const all_value = state.MoneyLink || {};
  const voucher_list = all_value.voucher_list;
  const temp_snap_voucher_list = state.Snapshot.temp_snap_voucher_list || [];


  const session_value = state.Session || {};
  const features_permissions_list = session_value.features_permissions_list || [];

  return {
    voucher_list, temp_snap_voucher_list, features_permissions_list
  };
}

export default connect(mapStateToProps)(SnapshotLedger);