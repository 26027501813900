import React from 'react'
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import { allowNumbersOnly, allowNumbersAndDecimalsOnly } from '../../Utils/RegaxValidation';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { decodeHtml } from '../../Helpers/HelperFunction';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { toast } from 'react-toastify';
import CostCenter from './CostCenter';
import { roundOffValue } from '../../Views/Snapshot/Roundoff';


const SelectSearch = {
	control: styles =>
	({
		...styles,
		color: '#2C275F!important',
		borderColor: '#707070',
		boxShadow: '0px !important',
		// fontSize : '17px',
		fontFamily: 's-sbold !important',

		'&:focus': {
			borderColor: '#77d7b1 !important',
			boxShadow: '1px #77d7b1 !important',
		},
		'&:hover': {
			borderColor: '#77d7b1 !important',
			boxShadow: '0px !important',
		},

	}),
	placeholder: styles => ({ ...styles, color: '#2C275F' }),
}


class CostCategory extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			guid: this.props.guid,
			type: this.props.type,
			category_option: !!this.props.category_name ? { label: decodeHtml(this.props.category_name), value: this.props.category_guid } : "",
			ledgerErrorMessage: "",
			is_permission_granted: true, //this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE),
			voucher_nos: this.props.voucherNos,
			type_disabled: false,
			new: true,
			category_guid: this.props.category_guid,
			cost_centers: this.props.cost_centers,
			cost_center_list: [],
		}
	}

	componentDidMount = () => {
		if (!!this.state.category_option && !!this.state.category_option.value) {
			this.getCostCenters(this.state.category_option.value);
		}
	}

	// componentWillReceiveProps(nextProps) {
	// 	if (nextProps.category_guid !== this.props.category_guid) {
	// 		//alert("not same");
	// 	}
	// }

	categoryErrorMessage = () => {
		if (!!!this.state.category_option) {
			return "Please Select Category"
		} else return null
	}

	 countDecimalDigits(number) {
		const decimalPart = number.toString().split('.')[1]; // Split the number at the decimal point
		return decimalPart ? decimalPart.length : 0; // Return the length of the decimal part or 0 if none
	}
	 hasZeroAfterDecimal(num) {
		// Convert the number to a string and split at the decimal point
		const strNum = num.toString();
		
		if (strNum.includes('.')) {
			// Get the part after the decimal point
			const decimalPart = strNum.split('.')[1];
			// Check if all characters in the decimal part are zeros
			return decimalPart.split('').every(char => char === '0');
		}
		
		return false; // No decimal point, so no zeros after decimal
	}
	costCenterAmtErrorMessage = () => {
		let cost_categories = this.props.cost_categories;
		console.log("cost category",this.props.cost_categories)
		let sum = cost_categories[this.props.cost_category_index].cost_centers.reduce((prev, curr) => {
			return parseFloat(prev) + parseFloat(curr.cost_center_amount);			
		}, 0);
	
		console.log("ledger amount :::",this.props.ledger_amount)
		if(this.props.ledger_amount != undefined){
			if(this.countDecimalDigits(this.props.ledger_amount) == 1){
				sum = sum.toFixed(1)
			}else{
				sum = sum.toFixed(2)
			}
		}
		
		sum = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,sum) : sum

		
		// console.log(this.countDecimalDigits(this.props.ledger_amount) , this.countDecimalDigits(sum))
		if(this.props.ledger_amount != undefined){
			if(this.countDecimalDigits(this.props.ledger_amount) == 0 && (this.countDecimalDigits(sum) == 2 || this.countDecimalDigits(sum) == 1) && (this.hasZeroAfterDecimal(sum))){
				sum = Math.floor(sum)
			}
		}
		console.log("sum::::",sum)
		if (this.props.ledger_amount != sum) {
			return "Ledger amount and total cost center amount should match."
		} else return null
	}

	handleCategoryChange = (category) => {
		if (!!category) {
			let category_name = category.label;
			let category_guid = category.value;
			this.setState({ category_option: { label: decodeHtml(category_name), value: category_guid }, category_guid: category_guid })

			let cost_category = this.props.cost_categories_list.find(x => x.value == category_guid);
			let cost_categories = this.props.cost_categories;
			cost_categories[this.props.cost_category_index].category_guid = category_guid;
			cost_categories[this.props.cost_category_index].category_id = category.id;
			cost_categories[this.props.cost_category_index].category_name = category.name;
			cost_categories[this.props.cost_category_index].transaction_voucher_ledger_cost_category_id = cost_category.transaction_voucher_ledger_cost_category_id;

			let cost_center = {
				"cost_center_guid": "",
				"cost_center_id": 0,
				"cost_center_name": "",
				"cost_center_amount": this.props.ledger_amount,
				"transaction_voucher_ledger_cost_category_cost_centre_id": 0
			}

			let cost_centers = cost_categories[this.props.cost_category_index].cost_centers;
			cost_categories[this.props.cost_category_index].cost_centers = [cost_center];

			this.setState({ cost_centers: [] }, () => {
				this.setState({ cost_centers })
			})

			this.getCostCenters(category_guid);
			this.props.isFormDirty();

		} else {
			if (!!this.props.bulk_ledger_type) {
				this.props.removeCostCategory(this.props.cost_category_index, this.props.bulk_ledger_type);
			} else {
				this.props.removeCostCategory(this.props.cost_category_index);
				this.props.isFormDirty();
			}
		}
	}

	updateCostCenter = (data) => {
		//let cost_categories = this.props.cost_categories;
		this.props.cost_categories[this.props.cost_category_index].cost_centers = data;
		this.setState({ cost_centers: [] }, () => {
			this.setState({ cost_centers: data })
		})
	}

	removeCostCategory = () => {
		if (!!this.props.bulk_ledger_type) {
			this.props.removeCostCategory(this.props.cost_category_index, this.props.bulk_ledger_type);
		} else {
			this.props.removeCostCategory(this.props.cost_category_index);
		}
	}

	getCostCenters = (category_guid = "") => {
		let category = this.props.cost_categories_list.find(category => category.value == category_guid);
		if (!!category) {
			let cost_center_list = category.cost_centres.map((cost_centre) => {
				cost_centre.label = decodeHtml(cost_centre.name);
				cost_centre.value = cost_centre.guid
				return cost_centre;
			})
			this.setState({ cost_center_list })
		} else {
			this.setState({ cost_center_list: [] })
		}
	}

	onAddCostCenter = () => {
		let cost_categories = this.props.cost_categories;
		console.log("cost category",this.props.cost_categories)
		let sum = cost_categories[this.props.cost_category_index].cost_centers.reduce((prev, curr) => {
			return prev + parseFloat(curr.cost_center_amount);
		}, 0);
		console.log(":::::::::::",sum)
		let cost_center_amount = (this.props.ledger_amount - sum);
		cost_center_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,cost_center_amount) :cost_center_amount.toFixed(2);
		console.log(":::::cost center amount",cost_center_amount)
		if (cost_center_amount > 0) {
			let cost_center = {
				"cost_center_guid": "",
				"cost_center_id": 0,
				"cost_center_name": "",
				"cost_center_amount": cost_center_amount,
				"transaction_voucher_ledger_cost_category_cost_centre_id": 0
			}
			cost_categories[this.props.cost_category_index].cost_centers.push(cost_center);
			this.setState({ cost_centers: cost_categories[this.props.cost_category_index].cost_centers })
			this.props.isFormDirty();
		}
	}

	removeCostCenter = (index) => {
		let cost_centers = this.state.cost_centers;
		cost_centers.splice(index, 1);
		this.setState({ cost_centers: [] }, () => {
			this.setState({ cost_centers: cost_centers })
		})
	}

	render() {
		const read_only = this.props.read_only;

		let cost_center_list = []
		let category = this.props.cost_categories_list.find(category => category.value == this.state.category_guid);
		if (!!category) {
			let list = category.cost_centres.map((cost_centre) => {
				cost_centre.label = decodeHtml(cost_centre.name);
				cost_centre.value = cost_centre.guid
				return cost_centre;
			})
			cost_center_list = list
		}
		//console.log(cost_center_list)
		return (
			<>
				{/* cost center */}
				<tr key={"category_" + this.props.cost_category_index + this.props.category_guid}>
					<td></td>
					<td colSpan={2}>
						<Select
							className={"Select-Search " + (!!this.props.is_leder_recommended ? "input-bg-green " : "")}
							classNamePrefix={(!!this.props.is_leder_recommended ? "Select SelectLedger input-bg-green " : "Select category ledger_sc")}
							//classNamePrefix="Select category ledger_sc"
							value={
								this.props.cost_categories_list.filter(option => option.value == this.state.category_guid).map(cost_category => ({ "label": decodeHtml(cost_category.name), "value": cost_category.guid }))
							}
							isDisabled={!this.state.is_permission_granted || read_only || !this.props.cost_categories_list.length}
							isClearable={true}
							isRtl={false}
							isSearchable={true}
							name="cost_category"
							placeholder="Select Category..."
							options={this.props.cost_categories_list}
							styles={SelectSearch}
							onChange={(e) => this.handleCategoryChange(e)}
						/>
						<small className="error_right d-block mb-0">{(!this.props.is_valid_ledger) && this.categoryErrorMessage()}</small>
						<small className="error_right d-block mb-0">{(!this.props.is_valid_ledger) && this.costCenterAmtErrorMessage()}</small>
					</td>
					{/* <td></td> colSpan={2} className="text-right" */}
					<td className='croslt'>
						{
							(!!this.props.cost_category_index && !(!this.state.is_permission_granted || read_only)) &&
							<span class="icon-Cross-with-grey-circle croslt-icon" onClick={() => this.removeCostCategory(this.props.cost_category_index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
						}
					</td>
				</tr>

				{
					this.state.cost_centers.map((cost_center, index1) => {
						return <CostCenter
							key={"cost_center_" + this.props.ledger_index + this.props.cost_category_index + index1}
							ledger_index={this.props.ledger_index}
							is_leder_recommended={this.props.is_leder_recommended}
							cost_category_index={this.props.cost_category_index}
							cost_center_index={index1}
							cost_center_amount={cost_center.cost_center_amount}
							cost_center_guid={cost_center.cost_center_guid}
							cost_center_name={cost_center.cost_center_name}
							cost_centers={this.props.cost_centers}
							cost_center_list={cost_center_list}
							cost_center={{ label: decodeHtml(cost_center.name), value: cost_center.guid }}
							onAddCostCenter={this.onAddCostCenter}
							removeCostCenter={this.removeCostCenter}
							ledger_amount={this.props.ledger_amount}
							is_bulk_ledger={!!this.props.bulk_ledger_type}
							ledgerNameList={this.props.ledgerNameList}
							is_valid_ledger={this.props.is_valid_ledger}
							read_only={this.props.read_only}
							transactionAmount={this.props.transactionAmount}
							voucherNos={this.props.voucherNos}
							voucher_type={this.props.voucher_type}
							submit_click={this.props.submit_click}
							isFormDirty={this.props.isFormDirty}
							updateCostCenter={this.updateCostCenter}
							amount_round_off = {this.props.amount_round_off}
						/>
					})
				}

				{/* <tr>
					<td colSpan={3}></td>
					<td>
						{(this.state.is_permission_granted && !read_only) &&
							<button className="item-increment border-0 bg-transparent removebrd mt-0 p-0" onClick={() => this.onAddCostCenter()} >
								<span className="icon-plus"></span>
							</button>
						}
					</td>
				</tr> */}
			</>
		)
	}
}

function mapStateToProps(state) {
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];

	const money_link_values = state.MoneyLink || {};
	const cost_categories_list = money_link_values.cost_category_list || []
	return {
		features_permissions_list, cost_categories_list
	};
}

export default connect(mapStateToProps)(CostCategory);