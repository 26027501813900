import React, { Component } from "react";
import { Link, Redirect } from 'react-router-dom';
import Timer from '../../Components/Timer';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import logo from '../../Assets/img/logo.png';
import client_logo from '../../Assets/img/client-logo.png';

import { connect } from "react-redux";
import { login } from "../../Redux/Actions/Auth";
import Image from 'react-bootstrap/Image';
import { setAppilcationLoder } from "../../Redux/Actions/App";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from "../../Services/AuthService";

import { ReCaptcha } from 'react-recaptcha-v3';
import { loadReCaptcha } from 'react-recaptcha-v3';
import { RECAPTCHA_SITE_KEY } from '../../Utils/Config';
import SessionManagement from "../../Utils/SessionManagement";

import { confirm } from '../../Components/BoilerPlate/Confirm';

import accountantaf from '../../Assets/img/accountantaf.png';

import { Button } from 'react-bootstrap';


const required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Please complete this required field.</small>
    );
  }
};

const account_name_required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Account Name is required</small>
    );
  }
};

const email_required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Email is required</small>
    );
  }
};

const phone_required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Mobile Number is required</small>
    );
  }
};

const username_required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Username is required</small>
    );
  }
};

const password_required = (value) => {
  if (!value) {
    return (
      <small className="form-error-text">Password is required</small>
    );
  }
};

const email_char = (value) => {
  //const re_email = /^[ A-Za-z0-9.@-]+$/;
  const re_email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (!re_email.test(value)) {
    return (
      <small className="form-error-text">Invalid Email</small>
    );
  }
};

const check_first_letter = (value) => {
  if (!isNaN(value.charAt(0))) {
    return (
      <small className="form-error-text">Email should not start with numbers</small>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <small className="form-error-text">Invalid Email</small>
    );
  }
};

const vusername = (value) => {
  // let is_uname = /^[a-zA-Z0-9]{6,20}$/.test(value);
  let is_uname = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{6,20}$/.test(value)
  if (!value) {
    return (
      <small className="form-error-text">Username is required</small>
    );
  } else if (value.length < 6 || value.length > 20 || !is_uname) {
    return (
      <small className="form-error-text">Username should have 6-20 alphanumeric characters.</small>
    );
  }
};

const vpassword = (value) => {
  // let is_valid_pwd = /^[a-zA-Z0-9_ !@#*$&()\\-`.+,/\"]{6,20}$/.test(value);
  let is_valid_pwd = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,20}$/.test(value);
  if (value.length < 6 || value.length > 20 || !is_valid_pwd) {
    return (
      <small className="form-error-text">Password should have 6-20 alphanumeric {'&'} special characters.</small>
    );
  }
  /*let isValidPwd = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!#%*?&]{8,30}$/.test(value);
  if (!isValidPwd) {
    return (
      <small className="form-error-text">Password Should contain 1 special character,one capital Letter</small>
    );
  }*/
};

const phoneno = (value) => {
  let isnum = /^\d+$/.test(value);
  if (!isnum || value.length != 10) {
    return (
      <small className="form-error-text">Please enter valid Mobile Number</small>
    );
  }
  else if (value.charAt(0) == "0" ||
    value.charAt(0) == "1" ||
    value.charAt(0) == "2" ||
    value.charAt(0) == "3" ||
    value.charAt(0) == "4" ||
    value.charAt(0) == "5") {
    return (
      <small className="form-error-text">Please enter valid Mobile Number</small>
    )
  }
};

const re = /^[0-9\b]+$/;

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.proceedOTPcheck = this.proceedOTPcheck.bind(this);
    this.onChangeFullName = this.onChangeFullName.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangeEmailId = this.onChangeEmailId.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeNumber = this.onChangeNumber.bind(this);
    this.onChangeFirstinp = this.onChangeFirstinp.bind(this);
    this.onChangeSecondinp = this.onChangeSecondinp.bind(this);
    this.onChangeThirdinp = this.onChangeThirdinp.bind(this);
    this.onChangeFourthinp = this.onChangeFourthinp.bind(this);
    this.onPasteHandler = this.onPasteHandler.bind(this);

    let param_value = !!this.props.match.params.token ? this.props.match.params.token : "";
    let is_token = false;
    if (!!param_value) {
      is_token = param_value == "trail" ? false : true;
    }

    this.state = {
      fullName: "",
      username: "",
      emailId: "",
      password: "",
      number: "",
      type: "business",
      reason: {},
      incorrect_no: false,
      is_valid_fullname: false,
      successful: false,
      loading: false,
      registerPage: true,
      otpPage: false,
      otpExpired: false,
      registerComplete: false,
      firstinp: "",
      secondinp: "",
      thirdinp: "",
      fourthinp: "",
      timer: 5,
      token: !!this.props.match.params.token ? this.props.match.params.token : "",
      is_token: is_token,
      ca_logo_qualified_url: "",
      ca_full_name: "",
      ca_account_name: "",

      dialCodeId: 1,
      dialCode: "+91",
      flag: "https://storage.googleapis.com/buktec_static/flags/IN.png",
      countryListing: [],
      entity_id: "",
      disabled_resend_otp: true,
      is_active: 0
    };
  }

  toggleType = (type) => {
    if (this.state.is_token) {
      return true;
    }
    if (type == "ca") {
      this.setState({
        type: "ca"
      });
    }
    else {
      this.setState({
        type: "business"
      });
    }
  }

  onChangeFullName(e) {
    this.setState({
      fullName: e.target.value,
    });
    // red error on input field
    if (required(e.target.value) === undefined) {
      this.setState({
        is_fullname: false,
      });
    } else {
      this.setState({
        is_fullname: true,
      });
    }
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
    // red error on input field
    if (vusername(e.target.value) === undefined) {
      this.setState({
        is_username: false,
      });
    } else {
      this.setState({
        is_username: true,
      });
    }
  }

  onChangeEmailId(e) {
    this.setState({
      emailId: e.target.value,
      reason: {}
    });
    // red error on input field
    if (email(e.target.value) === undefined) {
      this.setState({
        is_emailId: false,
      });
    } else {
      this.setState({
        is_emailId: true,
      });
    }
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
    // red error on input field
    if (vpassword(e.target.value) === undefined) {
      this.setState({
        is_password: false,
      });
    } else {
      this.setState({
        is_password: true,
      });
    }
  }

  onChangeNumber(e) {
    // if value is not blank, then test the regex
    if (e.target.value === '' || re.test(e.target.value)) {
      if (e.target.value.length <= 10)
        this.setState({
          number: e.target.value,
        });
    }
    // red error on input field
    if (phoneno(e.target.value) === undefined) {
      this.setState({
        is_number: false,
      });
    } else {
      this.setState({
        is_number: true,
      });
    }
  }

  onPasteHandler(e) {
    e.preventDefault();
    let num = e.clipboardData.getData('text/plain').trim()
    console.log(num)
    this.setState({
      firstinp: num.charAt(0),
      secondinp: num.charAt(1),
      thirdinp: num.charAt(2),
      fourthinp: num.charAt(3)
    })
  }

  onChangeFirstinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        firstinp: ""
      });
    } else {
      this.setState({
        firstinp: e.target.value,
      }, () => { this.secondinp.focus(); });
    }
  }
  onChangeSecondinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        secondinp: ""
      }, () => { this.firstinp.focus(); });
    } else {
      this.setState({
        secondinp: e.target.value,
      }, () => { this.thirdinp.focus(); });
    }
  }
  onChangeThirdinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        thirdinp: ""
      }, () => { this.secondinp.focus(); });
    } else {
      this.setState({
        thirdinp: e.target.value,
      }, () => { this.fourthinp.focus(); });
    }
  }
  onChangeFourthinp(e) {
    if (e.target.value === '' || !re.test(e.target.value)) {
      this.setState({
        fourthinp: ""
      }, () => { this.thirdinp.focus(); });
    } else {
      this.setState({
        fourthinp: e.target.value,
      });
    }
  }


  handleRegister(e) {
    e.preventDefault();
    this.form.validateAll();

    if (this.state.fullName == "") {
      this.setState({
        is_fullname: true,
      });
    } else {
      this.setState({ is_fullname: false })
      var regName = /^[a-zA-Z]+$/;
      // if (!regName.test(this.state.fullName)) {
      //   this.setState({
      //     is_valid_fullname: true,
      //   });
      //   return
      // } else this.setState({ is_valid_fullname: false })
    }
    if (this.state.password == "") {
      this.setState({
        is_password: true,
      });
    } else
      this.setState({ is_password: false })

    if (this.state.emailId == "") {
      this.setState({
        is_emailId: true,
      });
    } else
      this.setState({ is_emailId: false })

    if (this.state.username == "") {
      this.setState({
        is_username: true,
      });
    } else
      this.setState({ is_username: false })

    if (this.state.number != "" && this.state.number.length != 10) {
      this.setState({
        incorrect_no: true,
      });
      return;
    } else this.setState({ incorrect_no: false })





    if (this.checkBtn.context._errors.length === 0) {

      this.setState({
        loading: true,
      });

      this.setState({
        recaptcha_all_from: "register"
      }, () => {
        // you will get a new token in verifyCallback
        this.recaptcha.execute();
      }
      )
    }
  }


  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!  
    // console.log(recaptchaToken, "<= your recaptcha token")
    this.setState({
      recaptchaToken: recaptchaToken,
    });
    if (this.state.loading) {
      if (this.state.recaptcha_all_from == "register") {
        this.callRegisterAPI();
      } else if (this.state.recaptcha_all_from == "otp") {
        this.callOTPAPI();
      }
    }

  }

  callRegisterAPI = () => {
    this.props.dispatch(setAppilcationLoder(true));
    const { dispatch, history } = this.props;
    if (this.checkBtn.context._errors.length === 0) {
      AuthService.register(this.state.type, this.state.fullName, this.state.emailId, this.state.number, this.state.username, this.state.password, this.state.recaptchaToken, this.state.dialCodeId, this.state.token).then(
        (data) => {
          this.props.dispatch(setAppilcationLoder(false));
          if (data.response_code == 200) {
            this.setState({
              register_id: data.result.id,
              loading: false,
              registerPage: false,
              otpPage: true,
              resetTimerTime: data.result.timer / 60,
              resetTimer: true
            });
            setTimeout(() => { this.setState({ disabled_resend_otp: false }) }, data.result.resend_after * 1000);
            // toast.success(data.message);
          } else if (data.response_code == 500) {
            toast.error(data.message);
            this.setState({
              loading: false,
            });
          } else {
            toast.error(data.message);
            this.setState({
              loading: false,
              reason: data.reason
            });
          }
        });

    } else {
      this.setState({
        loading: false,
      });
    }
  }

  proceedOTPcheck(e) {
    e.preventDefault();
    this.setState({
      invalidOTP: false,
      loading: true
    });
    if (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length)) {
      toast.error("OTP fields cannot be empty")
      this.setState({ loading: false })
      return false;
    }
    this.setState({
      recaptcha_all_from: "otp"
    })

    // you will get a new token in verifyCallback
    this.recaptcha.execute();
  }

  callOTPAPI = () => {

    this.props.dispatch(setAppilcationLoder(true));
    let OTP = "" + this.state.firstinp + this.state.secondinp + this.state.thirdinp + this.state.fourthinp;

    let entity_request_id = !!this.state.is_token ? this.state.entity_id : null
    AuthService.verifyEmailOTP(this.state.register_id, OTP, this.state.recaptchaToken, entity_request_id, this.state.emailId).then(
      async (data) => {
        this.props.dispatch(setAppilcationLoder(false));
        if (data.response_code == 200) {
          if (data.result.is_active == 1) {
            let user_detail = data.result;
            this.setState({ is_active: 1 });

            localStorage.setItem("user", JSON.stringify(user_detail.user_details));
            this.props.dispatch(login(user_detail));
            // user_detail.user_details.is_promotional = 1;
            SessionManagement.setUserDetail(user_detail.user_details);
            SessionManagement.setAccessToken(user_detail.access_token);
            SessionManagement.setRefreshToken(user_detail.refresh_token);

            // history.push("/profile-management/entities/");
            // window.location.reload();
            if (data.result.invite_available == 0)
              await confirm({
                confirmation: "Registration Successful. Your invitation has been expired. Please ask CA to create entity.",
                options: { type: "alert" }
              })
          }
          this.setState({ otpPage: false, registerComplete: true }, () => {
            if (data.result.is_active == 1)
              this.countDown()
          })

        } else {
          toast.error(data.message);
          this.setState({
            loading: false,
            invalidOTP: true
          });
        }
      });
  }

  tick = () => {
    if (this.state.timer > 0) {
      this.setState({ timer: this.state.timer - 1 }, () => {
        this.countDown()
      })
    } else {
      this.redirectHome();
    }
  }

  redirectHome = () => {
    let url = "/profile-management/entities/";
    if (!!this.state.entity_id);
    url = "/profile-management/pending/" + this.state.entity_id;

    window.location.href = window.location.origin + url;
    // this.props.history.push("/profile-management/entities/");
    // window.location.reload();
  }

  countDown = () => {
    setTimeout(this.tick, 1000);
  }

  resendOTP = () => {
    this.setState({
      firstinp: "", secondinp: "", thirdinp: "", fourthinp: "", invalidOTP: false, otpExpired: false
    })
    this.setState({
      resetTimer: false,
      disabled_resend_otp: true
    });
    AuthService.resendOTP(this.state.register_id).then(
      (data) => {
        if (data.response_code == 200) {
          toast.success(data.message);
          this.setState({
            loading: false,
            resetTimerTime: data.result.timer / 60,
            resetTimer: true
          });
          setTimeout(() => { this.setState({ disabled_resend_otp: false }) }, data.result.resend_after * 1000);
        } else {
          toast.error(data.message);
          this.setState({
            loading: false,
            invalidOTP: true,
            resetTimerTime: 0,
            resetTimer: true
          });
        }
      });
  }
  getUserDetail = () => {
    this.props.dispatch(setAppilcationLoder(true));
    AuthService.invite(this.state.token).then(
      (data) => {
        this.props.dispatch(setAppilcationLoder(false));
        if (data.response_code == 200) {
          this.setState({
            emailId: data.result.account_id,
            ca_logo_qualified_url: data.result.qualified_url,
            ca_full_name: data.result.user_fullname,
            ca_account_name: data.result.account_fullname,
            entity_id: data.result.id
          })
        } else {
          toast.error("Invalid url or invite link expired");
          this.setState({ token: "" })
          this.props.history.push("/error-page")
        }
      });
  }

  toggleDialCode = () => {
    this.setState({
      showdialCode: !this.state.showdialCode,
    });
  }

  getCountryListing = () => {
    AuthService.countryListing().then(
      (data) => {
        if (data.response_code == 200) {
          this.setState({
            countryListing: data.result.dialcodes
          });
        }
      });
  }

  componentDidMount() {
    this.getCountryListing();
    loadReCaptcha(RECAPTCHA_SITE_KEY);
    if (this.state.is_token)
      this.getUserDetail();
  }

  setDailCode = (country) => {
    this.setState({
      dialCodeId: country.id,
      dialCode: country.dialCode,
      flag: country.flag,
      showdialCode: !this.state.showdialCode,
    });
  }

  render() {
    const { message } = this.props;

    return (<>
      <div className="container-fluid login-container">
        <div className="row no-gutters">

          {/* { 1 == 1 ? */}
          {!!this.state.token ?
            <>
              <div className="col-lg-3 col-md-12">
                <div className="login-logo">
                  <img src={logo} className="img-fluid onboarding-logo" alt="logo" />
                </div>
              </div>

              <div className="col-lg-3 col-md-12 three-sec-form">
                {/* { 1 == 0 ? */}
                {!!!this.state.ca_full_name ?
                  <div>
                    <h5>Welcome to free trial</h5>
                    <p>Simplify, digitize and automate your accounting process.</p>
                  </div>
                  :
                  <div>
                    <p className="mb-2">Brought to you by</p>
                    {!!!this.state.ca_logo_qualified_url ? "" : <Image src={this.state.ca_logo_qualified_url} thumbnail className="img-fluid logo_qualified mb-3" />}

                    <h4>{this.state.ca_account_name}</h4>
                    <p>{this.state.ca_full_name} has invited you to join Buktec platform. please complete the registration process to onboard</p>
                  </div>
                }
              </div>
            </>
            :
            <>
              {/* login-logo */}
              <div className="col-lg-6 col-md-6">
                <div className="login-logo">
                  <img src={logo} className="img-fluid onboarding-logo" alt="logo" />
                </div>
              </div>
              {/* End login-logo */}
            </>
          }



          {/* Form */}
          <div className={"d-flex align-items-center justify-content-center " + (this.state.token ? "col-lg-6 col-md-12 " : "col-lg-6 col-md-6")}>
            <div className="onboarding-form ">
              <div className="onboarding-form-width">

                <div className="d-flex">
                  <ul className="process_step_bar progres-li-sm mb-2">
                    <li className={!this.state.otpPage && !this.state.registerComplete ? "dot_process" : "active_process"}>Register</li>
                    <li className={(this.state.otpPage || this.state.registerComplete) ? (this.state.otpPage && !this.state.registerComplete ? "dot_process" : "active_process") : ""}>OTP Verification</li>
                    <li className={this.state.registerComplete ? "active_process" : " "}>Complete</li>
                  </ul>
                </div>

                {/* Register form  */}
                {this.state.registerPage &&
                  <>
                    <h3 className="login-title">Register As</h3>

                    <Form onSubmit={this.handleRegister} ref={(c) => { this.form = c; }} >

                      {/* Business / Accounting Firm Button */}
                      <div className="form-group ba-ca-btn">
                        <Button variant={"outline-success baca_btn " + (this.state.type == "business" ? "active" : "")} onClick={() => this.toggleType("business")}>
                          <span className="baca_btn_icon"><span className="icon-Mask-Group-1 ico-moon"></span></span>
                          <span className="baca_btn_text">Business</span>
                        </Button>
                        <Button variant={"outline-success baca_btn " + (this.state.type == "ca" ? "active" : "")} onClick={() => this.toggleType("ca")}>
                          <span className="baca_btn_icon"><img src={accountantaf} className="img-fluid mtm7" alt="Accounting Firm" /></span>
                          <span className="baca_btn_text">Accounting Firm</span>
                        </Button>
                      </div>
                      {/* End Business / Accounting Firm Button */}


                      <div className="form-group">
                        {this.state.type == 'ca' ? <lable className="lable-input">Account Name<span className="red-star">*</span></lable> :
                          <lable className="lable-input">Account Name<span className="red-star">*</span></lable>}
                        <Input
                          type="text"
                          className={"form-control " + (this.state.is_fullname || this.state.is_valid_fullname ? "wrong-red" : "")}
                          name="fullName"
                          value={this.state.fullName}
                          onChange={this.onChangeFullName}
                          validations={[account_name_required]}
                        />
                        {this.state.is_valid_fullname && <small className="form-error-text">Invalid Full Name</small>}
                        {this.state.reason.full_name != undefined && <small className="form-error-text">{this.state.reason.full_name}</small>}
                      </div>


                      <div className="form-group">
                        <lable className="lable-input">Email<span className="red-star">*</span></lable>
                        <Input
                          type="text"
                          className={"form-control " + (this.state.is_emailId ? "wrong-red" : "")}
                          disabled={this.state.is_token}
                          name="emailId"
                          value={this.state.emailId}
                          onChange={this.onChangeEmailId}
                          validations={[email_required, check_first_letter, email]}
                        />
                        {this.state.reason.email != undefined && <small className="form-error-text">{this.state.reason.email}</small>}
                      </div>


                      {/* Mobile Number */}
                      <div className="form-group">
                        <lable className="lable-input">Mobile Number<span className="red-star">*</span></lable>

                        <div className="reg_mob_no">
                          <div className="flag-toggle">
                            <div className="form-control btn dropdown-toggle flag-dropdown" data-toggle="dropdown" onClick={this.toggleDialCode} aria-haspopup="true" aria-expanded="true">
                              <span><img src={this.state.flag} /> {this.state.dialCode}</span>
                            </div>

                            <ul className={"dropdown-menu " + (this.state.showdialCode ? "show" : "")}>
                              {
                                this.state.countryListing.map((country) =>
                                  <li key={country.isoCode} onClick={() => this.setDailCode(country)} className={"dropdown-item " + (country.dialCode == this.state.dialCode ? "active" : "")}><img src={country.flag} /> {country.name} {country.dialCode}</li>
                                )
                              }
                            </ul>
                          </div>

                          <div className="flex-fill">
                            <Input
                              type="text"
                              className={"form-control " + (this.state.incorrect_no ? "wrong-red" : "")}
                              name="number"
                              maxLength={10}
                              value={this.state.number}
                              onChange={this.onChangeNumber}
                              validations={[phone_required, phoneno]}
                            />
                          </div>
                          {/* <input id="phone" name="phone" type="tel" className="form-control"/> */}
                        </div>

                        {this.state.reason.phone_number != undefined && <small className="form-error-text">{this.state.reason.phone_number}</small>}
                        {/* <br/>{this.state.incorrect_no && <small className="form-error-text">Incorrect Mobile Number</small>} */}
                      </div>
                      {/* End Mobile Number */}


                      <div className="form-group">
                        <lable className="lable-input">Username (6-20 alphanumeric characters)<span className="red-star">*</span></lable>
                        <Input
                          type="text"
                          className={"form-control " + (this.state.is_username ? "wrong-red" : "")}
                          name="username"
                          maxLength={20}
                          value={this.state.username}
                          onChange={this.onChangeUsername}
                          validations={[username_required, vusername]}
                        />
                        {this.state.reason.username != undefined && <small className="form-error-text">{this.state.reason.username}</small>}
                      </div>


                      <div className="form-group">
                        <lable className="lable-input">Password (6-20 alphanumeric & special characters)<span className="red-star">*</span></lable>
                        <Input
                          type="password"
                          className={"form-control " + (this.state.is_password ? "wrong-red" : "")}
                          name="password"
                          maxLength={20}
                          value={this.state.password}
                          onChange={this.onChangePassword}
                          validations={[password_required, vpassword]}
                        />
                        {this.state.reason.password != undefined && <small className="form-error-text">{this.state.reason.password}</small>}
                      </div>

                      {/* Button */}
                      <button type="submit" className={"btn btn-success btn-block apply_btn_new position-relative " + (this.state.loading ? "position-relative" : "")} disabled={this.state.loading}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}
                        {this.state.type == 'ca' ? <span>Register as Accountant</span> : <span>Register as Business</span>}
                      </button>
                      {/* End Button */}


                      <div className="login-p-text">
                        <p>By selecting Login, you agree to our <a href="https://thebuktec.com/new/terms-and-conditions/" target="_blank" className="blue-text s-sbold">Terms of Use</a> and have read and acknowledged our <a href="https://thebuktec.com/new/privacy-policy/" target="_blank" className="blue-text s-sbold"> Privacy Policy</a>.</p>

                        <p>Already have account ? <Link to="/login" className="blue-text s-sbold">Login</Link></p>

                        <ReCaptcha
                          ref={ref => this.recaptcha = ref}
                          sitekey={RECAPTCHA_SITE_KEY}
                          action='register_action'
                          verifyCallback={this.verifyCallback}
                        />
                        <p className="mb-0">Invisible reCAPTCHA by Google</p>

                        <CheckButton
                          style={{ display: "none" }}
                          ref={(c) => {
                            this.checkBtn = c;
                          }}
                        />
                        {/* <div className="check-box ">
                          <input className="styled-checkbox" id="styled-checkbox-11" type="checkbox" checked={this.state.tnc_check} name="tnc_check" onChange={this.handleChange} />
                          <label htmlFor="styled-checkbox-11" className="label-text">By selecting Sign In, you agree to our Terms and have read and acknowledge our Global Privacy Statement.</label>
                        </div> */}
                        {/* <div className="check-box mt-4">
                            <input className="styled-checkbox" id="styled-checkbox-11" type="checkbox" checked={this.state.tnc_check} name="tnc_check" onChange={this.handleChange} />
                            <label htmlFor="styled-checkbox-11" className="label-text blu-color s-sbold ft-sz-20 mb-0">I accept the Terms and Conditions</label>
                        </div> */}
                      </div>
                    </Form>
                  </>
                }
                {/* End Register form  */}


                {/* OTP form */}
                {this.state.otpPage &&
                  <>
                    <h3 className="login-title mt-4">OTP Verification</h3>
                    <lable className="lable-input">Enter OTP Code sent to <span className="s-sbold blue-text">{this.state.emailId}</span></lable>

                    <form>
                      <div className="form-group">
                        <div className="otp-reg mt-1 mb-1">
                          <input type="text" maxLength="1"
                            value={this.state.firstinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeFirstinp}
                            contentEditable={true}
                            ref={(ip) => this.firstinp = ip}
                            // className={"form-control otprinput " + (this.state.invalidOTP ? "red" : "")} 
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.secondinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeSecondinp}
                            ref={(ip) => this.secondinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.thirdinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeThirdinp}
                            ref={(ip) => this.thirdinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                          <input type="text" maxLength="1"
                            value={this.state.fourthinp}
                            onPaste={this.onPasteHandler}
                            onChange={this.onChangeFourthinp}
                            ref={(ip) => this.fourthinp = ip}
                            className={"form-control otprinput " + (this.state.invalidOTP ? "" : "")} />
                        </div>

                        {/* <div className="otp-reg mb-1">
                          {this.state.invalidOTP && <small className="form-error-text w-100">The entered OTP is incorrect</small>}
                        </div> */}
                        <div className="otp-reg mb-1">
                        {this.state.otpExpired 
                        ? <small className="form-error-text w-100">OTP expired</small> 
                        : (this.state.invalidOTP && <small className="form-error-text w-100">The entered OTP is incorrect</small>)
                        }
                        </div>
                      </div>

                      <div className="login-p-text">
                        <p>Expire in {this.state.resetTimer ? <Timer initialMinute={this.state.resetTimerTime} onExpire={() => {this.setState({ otpExpired: true })}} /> : `00:00`}</p>
                        <p className="resend-otp-reg">
                          <span>Didn't receive OTP code ?</span>
                          <span className={this.state.disabled_resend_otp ? "resend-otp-text resend-otp-disabled" : "resend-otp-text"} onClick={() => { this.state.disabled_resend_otp ? "" : this.resendOTP() }}>Resend OTP</span>
                        </p>
                      </div>

                      <ReCaptcha
                        ref={ref => this.recaptcha = ref}
                        sitekey={RECAPTCHA_SITE_KEY}
                        action='register_action'
                        verifyCallback={this.verifyCallback}
                      />

                      {/* <button type="button" onClick={this.proceedOTPcheck} className={"btn btn-success btn-block apply_btn_new mt-4 position-relative " + (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length) ? " btn-bg-gray-" : " btn-bg-gray-") + (this.state.loading ? "position-relative" : "")} disabled={this.state.loading}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}
                        {this.state.type == 'ca' ? <span>Register as Accountant</span> : <span>Register as Business</span>}
                      </button> */}

                      <button type="button" onClick={this.proceedOTPcheck} className={"btn btn-success btn-block apply_btn_new mt-4 position-relative " + (!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length) ? "gray_btn_new" : " ") + (this.state.loading ? "position-relative" : "")} disabled={!(this.state.firstinp.length && this.state.secondinp.length && this.state.thirdinp.length && this.state.fourthinp.length) || this.state.loading}>
                        {this.state.loading && (<span className="spinner-border spinner-border-sm login-spinner-border"></span>)}
                        {this.state.type == 'ca' ? <span>Register as Accountant</span> : <span>Register as Business</span>}
                      </button>
                    </form>
                  </>
                }
                {/* End - OTP form */}


                {/* Complete */}
                {this.state.registerComplete ?
                  <div className="complet-form">
                    <span className="icon-check-mark-1 done-all-icon"></span>
                    <h3>All done!</h3>

                    {this.state.is_active == 1 ?
                      <>
                        <p className="mt-3 mb-0">You will be re directed to <span className="s-sbold blue-text">profile management in {this.state.timer} seconds</span></p>
                        <p>if not redirected please <a className="s-sbold blue-text" onClick={() => this.redirectHome()} href="">click here</a></p>
                      </>
                      :
                      <p className="mt-3">Please contact Admin to activate your account.</p>
                    }
                  </div>
                  :
                  null
                }
                {/* End - Complete */}


              </div>
              {/* onboarding-form-width */}
            </div>
          </div>
          {/* End Form */}
        </div>
      </div>
    </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(Register);