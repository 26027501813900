import React, { Component } from "react";
import Modal from 'react-bootstrap/Modal';
import Dropzone from "react-dropzone";
import MasterService from '../../Services/MasterService';
import { SNAPSHOT_FILES_CODE, CREATE_REIMBUSRMENT_SNAPSHOT, CREATE_BUSSINESS_SNAPSHOT } from '../../Helpers/Constants';
import SnapshotService from '../../Services/Snapshot/SnapshotService';
import { changeDateFormate } from '../../Helpers/DateFormat';
import ProgressBar from '../../Components/BoilerPlate/ProgressBar';
import { sendLog } from '../../Helpers/CreateLogs';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { toast } from 'react-toastify';
// import loaderGray from '../../Assets/img/load.gif';
import loaderGray from '../../Assets/img/loader-gray.gif';
import DirtyFieldModal from '../../Components/BoilerPlate/DirtyFieldModal';
import Button from 'react-bootstrap/Button';
import { connect } from "react-redux";
import { Link, withRouter, Prompt } from 'react-router-dom';
import { roundOffValue } from "../../Views/Snapshot/Roundoff";

class BulkUploadPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
            uploaded_files: [],
            all_files: [],
            uploading: false,
            is_smart: this.props.SMART_SCAN_SNAPSHOT ? true : false,
            uploading_file_percent: 0,
            file_url: "",
            file_uploaded_count: 0,
            file_inprogress_count: 0,
            file_rejected_count: 0,
            uploading_finished: false,
            max_files: props.system_parameter.bulk_snapshot_limit || 25,
            max_file_size: 1024 * 1000 * (props.system_parameter.snapshot_filesize || 10),
            interval_id: "",
            snapshots: [],
            dirty_popup_visible: false,
            drag_drop: true
        }

        console.log(this.props.snapshot_types)
    }

    componentDidMount = () => {
        window.addEventListener("dragover", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
        window.addEventListener("drop", function (e) {
            e = e || event;
            e.preventDefault();
        }, false);
    }

    onDrop = async (accepted, rejected) => {
        let all_files = [];

        if (this.state.all_files.length > 0) {
            this.setState({ drag_drop: false })
            //return
        }

        // rejected = rejected.sort(function(a, b){
        //     if(a.file.name.toLowerCase() < b.file.name.toLowerCase()) { return -1; }
        //     if(a.file.name.toLowerCase() > b.file.name.toLowerCase()) { return 1; }
        //     return 0;
        // })

        // accepted = accepted.sort(function(a, b){
        //     if(a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
        //     if(a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
        //     return 0;
        // })

        rejected.forEach((file, index) => {
            let error_msg = ""
            file.errors.forEach((error, index) => {
                if (error.code == 'file-too-large') {
                    error_msg = "File is larger than 10MB"
                }
                if (error.code == 'file-invalid-type') {
                    if (error_msg == "") {
                        error_msg = 'Invalid file type'
                    } else {
                        error_msg += ', Invalid file type'
                    }
                }
                if (error_msg == "") {
                    error_msg = 'Cannot read file'
                }
            })

            all_files.push({
                name: file.file.name,
                size: file.file.size,
                message: error_msg,
                status: 'rejected',
                uploading: false,
                uploading_file_percent: 0,
            })
        });

        accepted.forEach((file, index) => {
            all_files.push({
                name: file.name,
                size: file.size,
                message: 'Waiting...',
                status: 'accepted',
                file: file,
                uploading_file_percent: 0,
                uploading: false
            })
        });

        this.setState({ all_files: [...this.state.all_files, ...all_files], file_rejected_count: rejected.length }, () => {
            this.setState({
                all_files: this.state.all_files.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                })
            })
        })
    };

    setRejectedCount = async () => {
        var res = this.state.all_files.filter(val => {
            if (val.status == 'rejected') {
                return val
            }
        })
        this.setState({ file_rejected_count: res.length })
    }

    getFileSize = (size) => {
        const fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
        let i = 0;
        while (size > 900) {
            size /= 1024;
            i++;
        }
        let exact_size = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
        return exact_size;
    }

    clearFiles = () => {
        this.setState({
            uploaded_files: [],
            all_files: [],
            uploading: false,
            is_smart: true,
            uploading_file_percent: 0,
            file_url: "",
            file_uploaded_count: 0,
            file_inprogress_count: 0,
            file_rejected_count: 0,
            drag_drop: true
        })
    }

    waitforme(milisec) {
        return new Promise(resolve => {
            setTimeout(() => { resolve('') }, milisec);
        })
    }

    startUpload = async () => {
        //console.log(navigator.onLine)
        //return
        this.setState({ uploading: true })

        for (let i = 0; i < this.state.all_files.length; ++i) {
            let isOnline = navigator.onLine

            let files = this.state.all_files[i]
            let file = files.file
            if (files.status == 'accepted') {

                if (isOnline) {
                    files.uploading = true;
                    this.scrollTo('row' + i)

                    const file_type = file.type;
                    let blob = new Blob([file], { type: file_type });
                    let file_url = URL.createObjectURL(blob);
                    const file_data = await MasterService.uploadFileFromBlobUrl(file_url);

                    if (!file_data) {
                        message = "Error 101: Could not create snapshot";
                        files.status = 'rejected';
                        this.setRejectedCount()
                        files.uploading = false;
                    }

                    //add progress value
                    this.setState({ uploading_file_percent: 20 });

                    let signed_url_data = await MasterService.getSignedUploadFile(file_data, file.name, file.type, SNAPSHOT_FILES_CODE)
                    //console.log(signed_url_data)
                    //add progress value
                    if (this.state.is_smart) {
                        this.setState({ uploading_file_percent: 40 });
                    } else {
                        this.setState({ uploading_file_percent: 80 });
                    }

                    if (signed_url_data) {
                        if (signed_url_data == 'timeout') { //alert('aaa')
                            files.status = 'rejected';
                            files.message = "Could not connect to server";
                            files.uploading = false;
                            this.setRejectedCount()
                        } else {
                            let gcp_subpath = signed_url_data.gcp_subpath;
                            file_url = gcp_subpath;
                            let prediction_data = {
                                request_id:null,
                                prediction_id: null,
                                date_prediction_value: null,
                                invoice_number: null,
                                snapshot_number_prediction_value: null,
                                total_amount: null,
                                total_amount_prediction_value: null,
                                merchant_name: null,
                                company_self_name: null,
                                sgst_amount: null,
                                gst_status: null,
                                sgst_amount_prediction_value: null,
                                cgst_amount: null,
                                gst_status: null,
                                cgst_amount_prediction_value: null,
                                igst_amount: null,
                                gst_status: null,
                                igst_amount_prediction_value: null,
                                gst_total_amount: null,
                                total_gst_amount_prediction_value: null,
                                merchant_name: null,
                                merchant_id: null,
                                gst_no: null,
                                pan_number: null,
                                pan_number_prediction_value: null,
                                type: null,
                                snapshot_type_prediction_value: null,
                                merchant_id: null,
                                merchant_name_prediction_value: null,
                                merchant_id_prediction_value: null,
                                expense: 1,
                                tds_percentage: "",
                                tds_status: false,
                                is_msme: false,
                                is_perquisite: false,
                                is_gst_claim_eligible: false,
                                is_entity_mismatch: 0,
                                selected_tags: [],
                                category_id: "",
                                gst_no_prediction_status: 0,
                                total_amount_prediction_status: 0,
                                igst_amount_prediction_status: 0,
                                sgst_amount_prediction_status: 0,
                                cgst_amount_prediction_status: 0,
                                date_prediction_status: 0,
                                merchant_name_prediction_status: 0,
                                snapshot_number_prediction_status: 0,
                                merchant_id_prediction_status: 0,
                                pan_number_prediction_status: 0,
                                snapshot_type_prediction_status: 0,
                                expense_prediction_status: 0,
                                category_id_prediction_status: 0,
                                tds_percentage_prediction_status: 0,
                                tags_prediction_status: 0,
                                is_msme_prediction_status: 0,
                                is_entity_mismatch_prediction_status: 0,
                                //is_igst: false,
                            }
                            let prediction_done = false;
                            if (this.state.is_smart) {

                                await SnapshotService.getPredictionData(gcp_subpath, true, null).then((data) => {
                                    if (data.response_code === 200) {
                                        //add progress value
                                        this.setState({ uploading_file_percent: 80 });

                                        file_url = data.result.file_path;
                                        prediction_data.prediction_id = data.result.prediction_id


                                        data.result.payload.forEach((payload) => {
                                            if (payload.status == 1) {
                                                switch (payload.displayName) {
                                                    case "INVOICE_DATE":
                                                        var newdate = payload.value;
                                                        prediction_data.date_prediction_value = newdate;
                                                        prediction_data.date_prediction_status = payload.status;
                                                        break;
                                                    case "INVOICE_NUMBER":
                                                        prediction_data.invoice_number = payload.value;
                                                        prediction_data.snapshot_number_prediction_value = payload.value;
                                                        prediction_data.snapshot_number_prediction_status = payload.status;
                                                        break;
                                                    case "TOTAL_AMOUNT":
                                                        prediction_data.total_amount = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.total_amount_prediction_value = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.total_amount_prediction_status = payload.status;
                                                        break;
                                                    case "sgst_final":
                                                        prediction_data.sgst_amount = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.gst_status = !!payload.value;
                                                        prediction_data.sgst_amount_prediction_value = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.sgst_amount_prediction_status = payload.status;
                                                        break;
                                                    case "cgst_final":
                                                        prediction_data.cgst_amount = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.gst_status = !!payload.value;
                                                        prediction_data.cgst_amount_prediction_value = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.cgst_amount_prediction_status = payload.status;
                                                        break;
                                                    case "IGST":
                                                        prediction_data.igst_amount = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.gst_status = !!payload.value;
                                                        prediction_data.igst_amount_prediction_value = roundOffValue(this.props.amount_round_off,payload.value);
                                                        prediction_data.igst_amount_prediction_status = payload.status;
                                                        break;
                                                    case "merchant_name":
                                                        prediction_data.merchant_name = payload.value;
                                                        prediction_data.merchant_name_prediction_status = payload.status;
                                                        break;
                                                    case "merchant_id":
                                                        prediction_data.merchant_id = !!payload.value ? payload.value : 0;
                                                        prediction_data.merchant_id_prediction_status = payload.status;
                                                        break;
                                                    case "gst_final":
                                                        prediction_data.gst_no = payload.value;
                                                        prediction_data.gst_no_prediction_status = payload.status;
                                                        break;
                                                    case "pan_number":
                                                        prediction_data.pan_number = payload.value;
                                                        prediction_data.pan_number_prediction_value = payload.value;
                                                        prediction_data.pan_number_prediction_status = payload.status;
                                                        break;
                                                    case "snapshot_type":
                                                        prediction_data.type = payload.value;
                                                        prediction_data.snapshot_type_prediction_value = payload.value;
                                                        prediction_data.snapshot_type_prediction_status = payload.status;
                                                        break;
                                                    case "expense":
                                                        prediction_data.expense = payload.value;
                                                        prediction_data.expense_prediction_status = payload.status;
                                                        break;
                                                    case "tds_percentage":
                                                        prediction_data.tds_percentage = payload.value
                                                        prediction_data.tds_status = !!payload.value ? true : false;
                                                        prediction_data.tds_percentage_prediction_status = payload.status;
                                                        break;
                                                    case "is_msme":
                                                        prediction_data.is_msme = !!payload.value ? payload.value : false;
                                                        prediction_data.is_msme_prediction_status = payload.status;
                                                        break;
                                                    case "is_entity_mismatch":
                                                        prediction_data.is_entity_mismatch = !!payload.value ? payload.value : false;
                                                        prediction_data.is_entity_mismatch_prediction_status = payload.status;
                                                        break;
                                                    case "category_id":
                                                        prediction_data.category_id = !!payload.value ? payload.value : "";
                                                        prediction_data.category_id_prediction_status = payload.status;
                                                        break;
                                                    case "tags":
                                                        let selected_tags = []
                                                        selected_tags = payload.value.map((tag, index) => {
                                                            return tag.id;
                                                        })
                                                        prediction_data.selected_tags = selected_tags;
                                                        prediction_data.tags_prediction_status = payload.status;
                                                        break;
                                                }
                                                if (!!prediction_data.merchant_name) {

                                                    prediction_data.merchant_name_prediction_value = prediction_data.merchant_name;
                                                    prediction_data.merchant_id_prediction_value = prediction_data.merchant_id;
                                                }
                                            }
                                        });
                                        // return;
                                        prediction_done = true;
                                    } else if (data == 'timeout') {
                                        files.status = 'rejected';
                                        files.message = "Could not connect to server";
                                        files.uploading = false;
                                        this.setRejectedCount()

                                    } else {
                                        files.message = "Error 103 : Could not create snapshot";
                                        files.status = 'rejected';
                                        this.setRejectedCount()
                                        files.uploading = false;
                                    }
                                })
                            }


                            await this.saveSnapshot(file_url, prediction_data, i, files, prediction_done)
                        }

                    } else {
                        files.message = "Error 102 : Could not create snapshot";
                        files.status = 'rejected';
                        this.setRejectedCount()
                        files.uploading = false;
                    }
                } else {
                    files.status = 'rejected';
                    files.message = "Could not connect to server";
                    files.uploading = false;
                    this.setRejectedCount()

                }
            }

            await this.waitforme(3000);
        }

        let intervalId = setInterval(() => {
            this.checkIfFinished()
        }, 2000);

        this.setState({ interval_id: intervalId })

    }


    newStartUpload = async () =>{
    
        if(this.state.is_smart){
            this.setState({ uploading: true })

            for (let i = 0; i < this.state.all_files.length; ++i) {
                let isOnline = navigator.onLine

                let files = this.state.all_files[i]
                let file = files.file
                console.log("files::::",file)
                if (files.status == 'accepted') {

                    if (isOnline) {
                        files.uploading = true;
                        this.scrollTo('row' + i)

                        const file_type = file.type;
                        let blob = new Blob([file], { type: file_type });
                        let file_url = URL.createObjectURL(blob);
                        const file_data = await MasterService.uploadFileFromBlobUrl(file_url);

                        if (!file_data) {
                            message = "Error 101: Could not create snapshot";
                            files.status = 'rejected';
                            this.setRejectedCount()
                            files.uploading = false;
                        }


                        //add progress value
                        this.setState({ uploading_file_percent: 20 });

                        //  let signed_url_data = await MasterService.getSignedUploadFile(file_data, file.name, file.type, SNAPSHOT_FILES_CODE)
                        //console.log(signed_url_data)
                        //add progress value
                        if (this.state.is_smart) {
                            this.setState({ uploading_file_percent: 40 });
                        } else {
                            this.setState({ uploading_file_percent: 80 });
                        }

                        let state_data = {
                            request_id:null,
                            prediction_id: null,
                            date_prediction_value: null,
                            invoice_number: null,
                            snapshot_number_prediction_value: null,
                            total_amount: null,
                            total_amount_prediction_value: null,
                            merchant_name: null,
                            company_self_name: null,
                            sgst_amount: null,
                            gst_status: null,
                            sgst_amount_prediction_value: null,
                            cgst_amount: null,
                            gst_status: null,
                            cgst_amount_prediction_value: null,
                            igst_amount: null,
                            gst_status: null,
                            igst_amount_prediction_value: null,
                            gst_total_amount: null,
                            total_gst_amount_prediction_value: null,
                            merchant_name: null,
                            merchant_id: null,
                            gst_no: null,
                            pan_number: null,
                            pan_number_prediction_value: null,
                            type: null,
                            snapshot_type_prediction_value: null,
                            merchant_id: null,
                            merchant_name_prediction_value: null,
                            merchant_id_prediction_value: null,
                            expense: 1,
                            tds_percentage: "",
                            tds_status: false,
                            is_msme: false,
                            is_perquisite: false,
                            is_gst_claim_eligible: false,
                            is_entity_mismatch: 0,
                            selected_tags: [],
                            category_id: "",
                            gst_no_prediction_status: 0,
                            total_amount_prediction_status: 0,
                            igst_amount_prediction_status: 0,
                            sgst_amount_prediction_status: 0,
                            cgst_amount_prediction_status: 0,
                            date_prediction_status: 0,
                            merchant_name_prediction_status: 0,
                            snapshot_number_prediction_status: 0,
                            merchant_id_prediction_status: 0,
                            pan_number_prediction_status: 0,
                            snapshot_type_prediction_status: 0,
                            expense_prediction_status: 0,
                            category_id_prediction_status: 0,
                            tds_percentage_prediction_status: 0,
                            tags_prediction_status: 0,
                            is_msme_prediction_status: 0,
                            is_entity_mismatch_prediction_status: 0,
                            //is_igst: false,
                        }
                        let prediction_done = false;

                        if (this.state.is_smart) {

                            const formData = new FormData();
                                formData.append('file',file);
                                formData.append('snapshot_type',8);
                                formData.append('expense',1)

                                formData.forEach((value, key) => {
                                    console.log(key, value);
                                });

                                await SnapshotService.newGetpredictionData(formData).then((data)=>{
                                    if(data.response_code == 200){
                                        console.log(":::::::::datata",data)
                                        this.setState({ uploading_file_percent: 80 });

                                        file_url = data.result.file_path;
                                        // prediction_data.prediction_id = data.result.prediction_id
                                        state_data.request_id = data.result.request_id

                                        let prediction_data = data.result.predictions;
                                        console.log(":::::::::",prediction_data);
                                        if(Object.keys(prediction_data).length > 0 && prediction_data !== null){
                                            console.log("condition  satisfied")
                                            if(prediction_data.hasOwnProperty('invoice_date') && !!prediction_data.invoice_date ){
                                                    var newdate = prediction_data.invoice_date;
                                                    state_data.date_prediction_value = newdate;
                                                    state_data.date_prediction_status = 1;
                                                    // this.setState({date:prediction_data.invoice_date})
                                            }
                                            if(prediction_data.hasOwnProperty('invoice_number') && !!prediction_data.invoice_number){
                                                    state_data.invoice_number = prediction_data.invoice_number;
                                                    state_data.snapshot_number_prediction_value = prediction_data.invoice_number;
                                                    state_data.snapshot_number_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('total_amount') && !!prediction_data.total_amount){
                                                    state_data.total_amount = roundOffValue(this.props.amount_round_off,prediction_data.total_amount);
                                                    state_data.total_amount_prediction_value = roundOffValue(this.props.amount_round_off,prediction_data.total_amount);
                                                    state_data.total_amount_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('sgst_amount') && !!prediction_data.sgst_amount){
                                                    state_data.sgst_amount = roundOffValue(this.props.amount_round_off,prediction_data.sgst_amount);
                                                    state_data.gst_status = !!prediction_data.sgst_amount;
                                                    state_data.sgst_amount_prediction_value = roundOffValue(this.props.amount_round_off,prediction_data.sgst_amount);
                                                    state_data.sgst_amount_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('cgst_amount') && !!prediction_data.cgst_amount){
                                                    state_data.cgst_amount = roundOffValue(this.props.amount_round_off,prediction_data.cgst_amount);
                                                    state_data.gst_status = !!prediction_data.cgst_amount;
                                                    state_data.cgst_amount_prediction_value = roundOffValue(this.props.amount_round_off,prediction_data.cgst_amount);
                                                    state_data.cgst_amount_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('igst_amount') && !!prediction_data.igst_amount){
                                                    state_data.igst_amount = roundOffValue(this.props.amount_round_off,prediction_data.igst_amount);
                                                    state_data.gst_status = !!prediction_data.igst_amount;
                                                    state_data.igst_amount_prediction_value = roundOffValue(this.props.amount_round_off,prediction_data.igst_amount);
                                                    state_data.igst_amount_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('merchant_name') && !!prediction_data.merchant_name){
                                                    state_data.merchant_name = prediction_data.merchant_name;
                                                    state_data.merchant_name_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('merchant_id')){
                                                    state_data.merchant_id = !!prediction_data.merchant_id ? prediction_data.merchant_id : 0;
                                                    state_data.merchant_id_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('gst_no') && !!prediction_data.gst_no){
                                                    state_data.gst_no = prediction_data.gst_no;
                                                    state_data.gst_no_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('pan_number') && !!prediction_data.pan_number){
                                                    state_data.pan_number = prediction_data.pan_number;
                                                    state_data.pan_number_prediction_value = prediction_data.pan_number;
                                                    state_data.pan_number_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('snapshot_type') && !!prediction_data.snapshot_type){
                                                    state_data.type = prediction_data.snapshot_type;
                                                    state_data.snapshot_type_prediction_value = prediction_data.snapshot_type;
                                                    state_data.snapshot_type_prediction_status = 1;
                                                    // this.setState({type:prediction_data.snapshot_type})
                                            }
                                            if(prediction_data.hasOwnProperty('expense')){
                                                    state_data.expense = prediction_data.expense;
                                                    state_data.expense_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('tds_percentage') && !!prediction_data.tds_percentage){
                                                    state_data.tds_percentage = prediction_data.tds_percentage
                                                    state_data.tds_status = !!prediction_data.tds_percentage ? true : false;
                                                    state_data.tds_percentage_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('is_msme') && !!prediction_data.is_msme){
                                                    state_data.is_msme = !!prediction_data.is_msme ? prediction_data.is_msme : false;
                                                    state_data.is_msme_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('is_entity_mismatch')){
                                                    state_data.is_entity_mismatch = !!prediction_data.is_entity_mismatch ? prediction_data.is_entity_mismatch : false;
                                                    state_data.is_entity_mismatch_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('category_id') && !!prediction_data.category_id){
                                                    state_data.category_id = !!prediction_data.category_id ? prediction_data.category_id : "";
                                                    state_data.category_id_prediction_status = 1;
                                            }
                                            if(prediction_data.hasOwnProperty('tags') && prediction_data.tags.length > 0){
                                                    let selected_tags = []
                                                    selected_tags = prediction_data.tags.map((tag, index) => {
                                                        return tag.id;
                                                    })
                                                    state_data.selected_tags = selected_tags;
                                                    state_data.tags_prediction_status = 1;
                                            }
                                            // if(prediction_data.hasOwnProperty('party_ledger_guid') && !!prediction_data.party_ledger_guid){
                                            //     this.setState({prediction_party_ledger_guid :prediction_data.party_ledger_guid})
                                            // }
                                            // if(prediction_data.hasOwnProperty('purchase_ledger_guid') && !!prediction_data.purchase_ledger_guid){
                                                
                                            //     this.setState({prediction_purchase_ledger_guid : prediction_data.purchase_ledger_guid})
                                            // }
                                            // if(prediction_data.hasOwnProperty('sales_ledger_guid') && !!prediction_data.sales_ledger_guid){
                                                
                                            //     this.setState({prediction_sales_ledger_guid:prediction_data.sales_ledger_guid})
                                            // }
                                            // if(prediction_data.hasOwnProperty('line_items') && prediction_data.line_items.length > 0){
                                            //      this.setState({line_items_data : prediction_data.line_items })
                                            // }
                                            if (!!state_data.merchant_name) {

                                                state_data.merchant_name_prediction_value = state_data.merchant_name;
                                                state_data.merchant_id_prediction_value = state_data.merchant_id;
                                            }
                                           

                                        }
                                        prediction_done = true;
                                    }else if (data == 'timeout') {
                                        files.status = 'rejected';
                                        files.message = "Could not connect to server";
                                        files.uploading = false;
                                        this.setRejectedCount()

                                    } else {
                                        files.message = "Error 103 : Could not create snapshot";
                                        files.status = 'rejected';
                                        this.setRejectedCount()
                                        files.uploading = false;
                                    }
                                })


                        }
                        console.log("data::::",state_data)
                        await this.saveSnapshot(file_url, state_data, i, files, prediction_done)
    
                    }else {
                        files.status = 'rejected';
                        files.message = "Could not connect to server";
                        files.uploading = false;
                        this.setRejectedCount()

                    } 

                }
                    await this.waitforme(3000);

            }
                    let intervalId = setInterval(() => {
                        this.checkIfFinished()
                    }, 2000);

                    this.setState({ interval_id: intervalId })
        }else{
            this.startUpload()
        }
    }

    checkIfFinished = () => {
        if (this.state.all_files.length == (this.state.file_rejected_count + this.state.file_uploaded_count)) {
            this.setState({ uploading_finished: true, uploading: false })
            clearInterval(this.state.interval_id)
        }
    }

    saveSnapshot = async (file_url, prediction_data, index, files, prediction_done) => {
        //let payment_status = (this.CREATE_REIMBUSRMENT_SNAPSHOT && !this.CREATE_BUSSINESS_SNAPSHOT) ? true : false;
        let payment_status = 2;
        let type = 8;
        let expense = 1;

        if (prediction_data.type) {
            type = prediction_data.type;
            //expense = prediction_data.expense
        }

        let additional_information = "";
        let merchant_flag = 0


        if (prediction_data.merchant_id == 0 && prediction_data.merchant_name != null && prediction_data.pan_number) {
            merchant_flag = 1;
        }


        if (prediction_data.merchant_id == 0 && ((prediction_data.merchant_name != null && !prediction_data.pan_number) || (!prediction_data.merchant_name && prediction_data.pan_number))) {
            additional_information = "Merchant Name: " + (prediction_data.merchant_name || "") + ", GSTIN: " + (prediction_data.gst_no || "") + ", PAN: " + (prediction_data.pan_number || "");
        }

        // if(!prediction_data.merchant_name && !prediction_data.gst_no && !prediction_data.pan_number) {
        //     additional_information = "";
        // }

        let gst_total_amount = roundOffValue(this.props.amount_round_off,(parseFloat(prediction_data.sgst_amount || 0) + parseFloat(prediction_data.cgst_amount || 0) + parseFloat(prediction_data.igst_amount || 0)));
        console.log("gst total amount",gst_total_amount)
        let snapshot_type = this.props.snapshot_types.find(types => types.id == type);
        //console.log(snapshot_type)
        let merchant_type = 2;

        if (snapshot_type) {
            merchant_type = snapshot_type.merchant_type;
            expense = snapshot_type.expense;
        }

        let tds_status = 0;
        let tds_amount = null;

        if (!!prediction_data.total_amount && !!prediction_data.tds_percentage) {
            let total_amt = parseFloat(prediction_data.total_amount || 0) - parseFloat(gst_total_amount || 0);

            tds_amount = roundOffValue(this.props.amount_round_off,(total_amt * parseFloat(prediction_data.tds_percentage || 0) / 100));

            if (tds_amount < 0) tds_amount = 0;
            tds_status = 1
        } else {
            tds_status = 0
        }

        let gst_status = prediction_data.gst_status ? 1 : prediction_data.gst_no ? 1 : 0;

        let data = {
            "expense": expense,
            "type": type,
            "merchant_type": merchant_type,
            "date": prediction_data.date_prediction_value ? prediction_data.date_prediction_value : changeDateFormate(new Date()),
            "merchant_name": prediction_data.merchant_name || null,
            "merchant_update": merchant_flag,
            "merchant_id": prediction_data.merchant_id || 0,
            "gst_no": prediction_data.gst_no || "",
            "invoice_number": prediction_data.invoice_number || "",
            "total_amount": parseFloat(prediction_data.total_amount) || null,
            "category_id": prediction_data.category_id || null,
            "tags": prediction_data.selected_tags,
            "additional_information": additional_information,
            "payment_status": 2,
            "payment_total": 0,
            "payment_information": [],
            "gst_status": gst_status,
            "sgst_amount": prediction_data.gst_status ? parseFloat(prediction_data.sgst_amount) : null,
            "cgst_amount": prediction_data.gst_status ? parseFloat(prediction_data.cgst_amount) : null,
            "igst_amount": prediction_data.gst_status ? parseFloat(prediction_data.igst_amount) : null,
            "gst_total_amount": prediction_data.gst_status ? parseFloat(gst_total_amount) : null,
            "tds_status": tds_status,
          //  "tds_percentage": tds_status == 1 ? parseFloat(prediction_data.tds_percentage) : null,
            "tds_details":[{
            base_amount:(parseFloat(prediction_data.total_amount) || 0) - (prediction_data.gst_status ? parseFloat(gst_total_amount) : 0) ,
            tds_amount:tds_status == 1 ? parseFloat(tds_amount) : 0,
            tds_percentage: tds_status == 1 ? parseFloat(prediction_data.tds_percentage) : 0,
            }],
            "tds_total_amount": tds_status == 1 ? parseFloat(tds_amount) : 0,
            "mode": this.state.is_smart && !!file_url ? 2 : 1,
            "file_url": file_url,
            "pan_number": prediction_data.pan_number ? prediction_data.pan_number : "",
            "gst_no_prediction_status": !this.state.is_smart ? 0 : prediction_data.gst_no_prediction_status,
            "total_amount_prediction_status": !this.state.is_smart ? 0 : prediction_data.gst_no_prediction_status,
            "igst_amount_prediction_status": !this.state.is_smart ? 0 : prediction_data.igst_amount_prediction_status,
            "sgst_amount_prediction_status": !this.state.is_smart ? 0 : prediction_data.sgst_amount_prediction_status,
            "cgst_amount_prediction_status": !this.state.is_smart ? 0 : prediction_data.cgst_amount_prediction_status,
            "date_prediction_status": !this.state.is_smart ? 0 : prediction_data.date_prediction_status,
            "merchant_name_prediction_status": !this.state.is_smart ? 0 : prediction_data.merchant_name_prediction_status,
            "snapshot_number_prediction_status": !this.state.is_smart ? 0 : prediction_data.snapshot_number_prediction_status,
            "merchant_id_prediction_status": !this.state.is_smart ? 0 : prediction_data.merchant_id_prediction_status,
            "pan_number_prediction_status": !this.state.is_smart ? 0 : prediction_data.pan_number_prediction_status,
            "snapshot_type_prediction_status": !this.state.is_smart ? 0 : prediction_data.snapshot_type_prediction_status,
            "expense_prediction_status": !this.state.is_smart ? 0 : prediction_data.expense_prediction_status,
            "category_id_prediction_status": !this.state.is_smart ? 0 : prediction_data.category_id_prediction_status,
            "tds_percentage_prediction_status": !this.state.is_smart ? 0 : prediction_data.tds_percentage_prediction_status,
            "tags_prediction_status": !this.state.is_smart ? 0 : prediction_data.tags_prediction_status,
            "is_msme_prediction_status": !this.state.is_smart ? 0 : prediction_data.is_msme_prediction_status,
            "is_entity_mismatch_prediction_status": !this.state.is_smart ? 0 : prediction_data.is_entity_mismatch_prediction_status,
            "approval_comment": null,
            "approval_status": null,
            "approved_amount": null,
            "new_tags": [],
            "voucher_status": 0,
            "is_duplicate": 0,
            "prediction_id": prediction_data.prediction_id || null,
            "vouchers": [],
            "source": 1,
            "is_msme": prediction_data.is_msme ? 1 : 0,
            "is_perquisite": 0,
            "is_gst_claim_eligible": prediction_data.is_gst_claim_eligible ? 1 : gst_status == 1 ? 1 : 0,
            "snapshot_type_update": 0,
            "synced_by_user": null,
            "entry_status": 1,
            "accounting_status": 3,
            "is_entity_mismatch": prediction_data.is_entity_mismatch ? 1 : 0,
            // "inventory_mode": ((type == 1 || type == 2) && this.props.accounting_mode == 2) ? 1 : 0,
            "inventory_mode": 0,
            "inventory_details": null,
            "has_igst": !this.state.is_smart ? 0 : prediction_data.igst_amount ? 1 : 0,
            "gst_summary": null,
            "hsn_sac_summary": null,
            "is_reimbursement": 0,
            "is_same_invoice": 0,
            "reimbursement_narration": null,
            "request_id":prediction_data.request_id || null,
            "is_gst_override":0,
            "is_star":0,
            "amount_round_off":this.props.amount_round_off

        }
        //console.log(data);
        //return;

        if (this.state.is_smart && prediction_data.gst_no) {
            let customer_gst = prediction_data.gst_no ? prediction_data.gst_no.slice(0, 2) : null || null;
            let entity_gst = (!!this.props.entity_gst_no) ? this.props.entity_gst_no.slice(0, 2) : null;

            if (customer_gst == entity_gst && customer_gst && entity_gst) {
                data.has_igst = 0;
                data.igst_amount = null;
            } else if (!customer_gst || !entity_gst) {
                data.has_igst = 1;
                data.igst_amount = null;
            } else if (customer_gst != entity_gst && customer_gst && entity_gst) {
                data.has_igst = 1;
                data.sgst_amount = null;
                data.cgst_amount = null;
            } else {
                data.has_igst = 0;
                data.igst_amount = null;
            }
        }

        data.gst_total_amount = roundOffValue(this.props.amount_round_off,parseFloat(data.sgst_amount || 0) + parseFloat(data.cgst_amount || 0) + parseFloat(data.igst_amount || 0));
        data.has_igst = !this.state.is_smart ? 0 : data.igst_amount ? 1 : 0;

        if (this.state.is_smart && !prediction_done) {
            return;
        }

        SnapshotService.createSnapshot(data).then(async (resp_data) => {
            let message = "";
            //console.log(data)
            if (resp_data.response_code == 200) {
                //add progress value
                this.setState({ uploading_file_percent: 100, file_uploaded_count: (this.state.file_uploaded_count + 1) });

                message = "Uploaded";
                //add log entry
                sendLog({ module_type: 1, module_id: resp_data.result.id, activity: ['Snapshot created using bulk upload'] })

                let snapshot = {
                    attachment_status: data.file_url,
                    category: null,
                    date: data.date,
                    expense: data.expense,
                    gst_status: data.gst_status,
                    id: resp_data.result.id,
                    is_star: 0,
                    message_status: 0,
                    name: data.merchant_name,
                    payment_information: [], // want from api
                    payment_status: data.payment_status,
                    snapshot_no: resp_data.result.snapshot_no,
                    status: data.snapshot_status,
                    tags: [],
                    task_status: 0,
                    tds_status: data.tds_status,
                    total_amount: data.total_amount ? data.total_amount : "",
                    type: parseInt(data.type),
                    is_duplicate: resp_data.result.is_duplicate,
                }

                this.setState(prevState => ({
                    snapshots: [...prevState.snapshots, snapshot]
                }))

            } else if (resp_data.response_code == 400) {
                if (resp_data.reason.is_duplicate) {
                    message = "Duplicate Snapshot";
                    files.status = 'rejected';
                } else if (resp_data.reason.is_same_invoice) {
                    message = "Is Same Invoice";
                    files.status = 'rejected';
                } else {
                    message = "Error 104 : Could not create snapshot";
                    files.status = 'rejected';
                }
            } else if (data == 'timeout') {
                files.message = "Could not connect to server";
                files.status = 'rejected';
            } else {
                files.message = "Could not connect to server";
                files.status = 'rejected';
            }

            files.message = message;
            files.uploading = false;
            this.setRejectedCount()

            setTimeout(() => {
                this.setState({ uploading_file_percent: 0 });
            }, 1000);
        })
    }

    finishUpload = () => {
        this.props.finishBulkUpload(this.state.snapshots)
    }

    removeFile = (e, index, status) => {
        e.stopPropagation()
        if (this.state.uploading == false || !this.state.uploading_finished) {
            var array = [...this.state.all_files]; // make a separate copy of the array
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ all_files: array });
            }
            if (status == 'rejected') {
                this.setState({ file_rejected_count: (this.state.file_rejected_count - 1) })
            }
        }
    }

    scrollTo = (position) => {
        const project = document.getElementById(position);
        project.scrollIntoView();
    }

    onDontSave = () => {
        //alert('onConfirm')
        this.setState({ dirty_popup_visible: false })
        this.props.toggleBulkUploadPopup()
    }

    render() {
        console.log("bulk amount round off",this.props.amount_round_off)
        return (
            <>
                <DirtyFieldModal
                    visible={this.state.dirty_popup_visible}
                    onCancel={() => this.setState({ dirty_popup_visible: false })}
                    onDontSave={this.onDontSave}
                    onClose={() => { }}
                />
                <Modal
                    show={this.props.show_bulk_upload_popup}
                    backdrop="static"
                    keyboard={false}
                    centered
                    className="popup-model bulk-popup-new"
                    onHide={() => {
                        if (this.state.uploading_finished) {
                            this.finishUpload()
                        } else {
                            if (this.state.all_files.length > 0) {
                                this.setState({ dirty_popup_visible: true })
                            } else {
                                this.props.toggleBulkUploadPopup()
                            }
                        }
                    }}
                >
                    <Modal.Header closeButton={this.state.uploading ? false : true}>
                        <Modal.Title>Bulk Upload</Modal.Title>
                    </Modal.Header>

                    {/* <Modal.Body className="clearfix pt-2 pb-2"> */}
                    <Modal.Body>
                        <div className="row mt-2">
                            <div className="col-8 d-flex align-items-center">
                                {this.state.all_files.length > this.state.max_files ?
                                    <p className="mb-0 red-text">Maximum {this.state.max_files} files can be uploaded at one time</p>
                                    : this.state.uploading_finished ? <p className="mb-0 dark-green-text">Snapshot bulk upload completed</p> : <p className="mb-0">Maximum number of files: <b>{this.state.max_files}</b>, Maximum upload file size: <b>{this.props.system_parameter.snapshot_filesize || 10} MB</b></p>
                                }
                            </div>

                            <div className="col-4 text-right">
                                <Button variant="success apply_btn_new" onClick={this.clearFiles} disabled={this.state.uploading || this.state.all_files.length == 0 || this.state.uploading_finished}>Clear Selection</Button>
                            </div>
                        </div>

                        <div className="bulk-popup-brd">
                            {this.state.all_files.length == 0 &&
                                <>
                                    {/* <div className="bulk-popup-table"> */}
                                    <Dropzone
                                        multiple={true}
                                        accept="image/jpg,image/jpeg,image/png,application/pdf"
                                        disabled={this.state.uploading || this.state.uploading_finished}
                                        onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                                        maxSize={this.state.max_file_size}
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <div {...getRootProps()} className="w-100 cpointer">
                                                <input {...getInputProps()} />
                                                <div className="bulk-popup-table bulk-popup-pluse">
                                                    <p>
                                                        <span className={"icon-plus"}></span><br></br>
                                                        <span className="d-block mt-3 mb-3">Click here to select files</span>
                                                        <span className={"d-block mb-3"}>OR</span>
                                                        <span className={"d-block"}>Drag & drop files here to create snapshots</span>
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                    {/* </div> */}
                                </>
                            }
                            {this.state.all_files.length != 0 && <>
                                <Dropzone
                                    multiple={true}
                                    accept="image/jpg,image/jpeg,image/png,application/pdf"
                                    disabled={this.state.uploading || this.state.uploading_finished}
                                    onDrop={(accepted, rejected) => this.onDrop(accepted, rejected)}
                                    maxSize={this.state.max_file_size}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div {...getRootProps()} className="w-100 cpointer">
                                            <input {...getInputProps()} />
                                            <div className="bulk-popup-table" onClick={(e) => e.stopPropagation()}>
                                                {/* <table class="table table-bordered mb-0"> */}
                                                <table class="table borderTable mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" width="50px" className="text-center">#</th>
                                                            <th scope="col">File Name</th>
                                                            <th scope="col" width="110px" className="text-right">Size</th>
                                                            <th scope="col" width="35%">Status</th>
                                                            <th scope="col" width="50px" className="text-center">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.all_files.map((file, index) => {
                                                            return <tr className={file.status == 'rejected' ? 'errorbup' : ''} key={index} id={'row' + index}>
                                                                <td className="text-center">{(index + 1)}</td>
                                                                <td>{file.name}</td>
                                                                <td className="text-right">{this.getFileSize(file.size)}</td>
                                                                {file.uploading ?
                                                                    <td className="va-middle">
                                                                        <ProgressBar key={index} bgcolor={'#77d7b1'} completed={this.state.uploading_file_percent} /></td>
                                                                    :
                                                                    <td><span className={file.status == 'rejected' ? "red-color" : ""}>{file.message}</span></td>
                                                                }

                                                                <td className="text-center">

                                                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled" className="">Delete</Tooltip>}>
                                                                        {this.state.uploading_finished || this.state.uploading ?
                                                                            <span className="icon-delete gray-icon cursornot"></span>
                                                                            :
                                                                            <span onClick={(e) => this.removeFile(e, index, file.status)} className="icon-delete red-icon cpointer"></span>
                                                                        }
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </>
                            }
                        </div>

                        <div className="row">
                            <div className="col-lg-6 col-md-12 d-flex align-items-center">

                                {(this.state.uploading || this.state.uploading_finished) &&
                                    <p className="bulk-upstatus">
                                        {this.state.uploading && <span><img src={loaderGray} className="img-fluid mr-2" style={{ height: '27px' }} /></span>}
                                        <span className="spanupst">Selected: {this.state.all_files.length}, </span>
                                        <span className="spanupst">Uploaded: {this.state.file_uploaded_count}, </span>
                                        <span className="spanupst">In Process: {(this.state.all_files.length - this.state.file_uploaded_count - this.state.file_rejected_count)}, </span>
                                        <span className="spanupst">Error: {this.state.file_rejected_count}</span>
                                    </p>
                                }
                            </div>

                            {/* <div className="col-lg-6 col-md-12 mt-0 mt-lg-0 mt-md-3 text-right d-flex align-items-center justify-content-end bottom-save-btn"> */}
                            <div className="col-lg-6 col-md-12 bulkb-save-btn">
                                <div className="toggle-switch-btn toggle-btn-bordered toggle-switch-center">
                                    <span className="toggle-btn-sec-text mr-4">Smart Scan</span>

                                    {/* <span className="d-flex align-items-center"> */}
                                    <span className="toggle-switch-center">
                                        <span className="toggle-btn-sec-text">No</span>
                                        <label className="switch">
                                            <input type="checkbox" value={this.state.is_smart} disabled={this.state.uploading || this.state.all_files.length == 0 || this.state.uploading_finished} checked={this.state.is_smart}
                                                onChange={(e) => {
                                                    const target = e.target;
                                                    this.setState({ is_smart: target.checked });
                                                }} />
                                            <span className={"slider round " + ((this.state.uploading || this.state.uploading_finished || this.state.all_files.length == 0) ? "disable-any disable-opacity" : "")}></span>
                                        </label>
                                        <span className="toggle-btn-sec-text">Yes</span>
                                    </span>
                                </div>

                                {/* <div className="bottom-save-btn ml-3">
                                    {(!this.state.uploading_finished) && 
                                        <button type="button" className="btn save-btn s-bold greenbtn" onClick={this.startUpload} disabled={this.state.uploading || this.state.all_files.length == this.state.file_rejected_count || this.state.all_files.length > this.state.max_files}>Start Upload</button>
                                    }

                                    {this.state.uploading_finished && 
                                        <button type="button" className="btn save-btn s-bold greenbtn" onClick={this.finishUpload} disabled={this.state.uploading}>Finish</button>
                                    }
                                </div> */}
                                <div className="bottom_save_btn ml-3">
                                    {(!this.state.uploading_finished) &&
                                        <Button variant="success apply_btn_new" onClick={this.props.is_new_model ? this.newStartUpload : this.startUpload} disabled={this.state.uploading || this.state.all_files.length == this.state.file_rejected_count || this.state.all_files.length > this.state.max_files}>Start Upload</Button>
                                    }

                                    {this.state.uploading_finished &&
                                        <Button variant="success apply_btn_new" onClick={this.finishUpload} disabled={this.state.uploading}>Finish</Button>
                                    }
                                </div>
                            </div>
                        </div>
                        {/* </>} */}
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
	const session_values = state.Session || {};
	const is_new_model = session_values.entity_config_param.is_ner_model;
    const amount_round_off = session_values.entity_config_param.amount_round_off

	return {
		is_new_model,amount_round_off
	};
  }
  
  export default connect(mapStateToProps)(withRouter(BulkUploadPopup));
