import React from 'react'
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { LEDGER_ENTRIES_CODE } from '../../Helpers/Constants';
import { allowNumbersOnly, allowNumbersAndDecimalsOnly } from '../../Utils/RegaxValidation';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input-field';
import { decodeHtml } from '../../Helpers/HelperFunction';
import MoneyLinkService from '../../Services/MoneyLink/MoneyLinkService';
import { toast } from 'react-toastify';
import { roundOffValue } from '../../Views/Snapshot/Roundoff';


const SelectSearch = {
	control: styles =>
	({
		...styles,
		color: '#2C275F!important',
		borderColor: '#707070',
		boxShadow: '0px !important',
		// fontSize : '17px',
		fontFamily: 's-sbold !important',

		'&:focus': {
			borderColor: '#77d7b1 !important',
			boxShadow: '1px #77d7b1 !important',
		},
		'&:hover': {
			borderColor: '#77d7b1 !important',
			boxShadow: '0px !important',
		},

	}),
	placeholder: styles => ({ ...styles, color: '#2C275F' }),
}


class CostCenter extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			guid: this.props.guid,
			type: this.props.type,
			cost_center_option: !!this.props.cost_center_name ? { label: decodeHtml(this.props.cost_center_name), value: this.props.cost_center_guid } : "",
			cost_center_amount: this.props.cost_center_amount,
			ledgerErrorMessage: "",
			is_permission_granted: true, //this.props.features_permissions_list.includes(LEDGER_ENTRIES_CODE),
			voucher_nos: this.props.voucherNos,
			type_disabled: false,
			new: true,
			cost_centers: this.props.cost_centers || [],
			cost_center: {},
			cost_center_guid: this.props.cost_center_guid
		}
	}

	componentDidMount = () => {
	}

	handleCostCenterChange = (cost_center) => {
		let cost_center_name = cost_center.label;
		let cost_center_guid = cost_center.value;
		this.setState({ cost_center_option: { label: decodeHtml(cost_center_name), value: cost_center_guid }, cost_center_guid })

		let tmp_cost_center = this.props.cost_center_list.find(x => x.value == cost_center_guid);

		let cost_centers = this.props.cost_centers;
		this.props.cost_centers[this.props.cost_center_index].cost_center_guid = cost_center_guid;
		this.props.cost_centers[this.props.cost_center_index].cost_center_id = tmp_cost_center.id;
		this.props.cost_centers[this.props.cost_center_index].cost_center_name = tmp_cost_center.name;
		this.props.cost_centers[this.props.cost_center_index].transaction_voucher_ledger_cost_category_cost_centre_id = tmp_cost_center.transaction_voucher_ledger_cost_category_cost_centre_id;

		this.props.updateCostCenter(this.props.cost_centers)
		this.props.isFormDirty()
	}

	// ------- Made by Ram ------- 
	amountChange = (value, name) => {
		let cost_centers = this.props.cost_centers;
		console.log("amount center chnages",this.props.amount_round_off)
		if(this.props.amount_round_off){
			this.setState({cost_center_amount:value},()=>{
			cost_centers[this.props.cost_center_index].cost_center_amount = this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.cost_center_amount) : this.state.cost_center_amount;
			})
		}else{
			cost_centers[this.props.cost_center_index].cost_center_amount = value;
			this.setState({ cost_center_amount: value })
		}
		
	}

	onBlurCostCenterAMt = async() => {
		await this.props.updateCostCenter(this.props.cost_centers)
		this.props.onAddCostCenter();
	}

	costCenterErrorMessage = () => {
		if (!!!this.state.cost_center_option) {
			return "Please Select Cost Center"
		} else return null
	}

	costCenterAmtErrorMessage = () => {
		if (this.state.cost_center_amount == 0 || this.state.cost_center_amount == '' || this.state.cost_center_amount == null) {
			return "Enter Amount"
		} else return null
	}

	render() {
		const read_only = this.props.read_only;

		//console.log(this.props.cost_center_list);
		return (
			<>
				{/* cost center */}
				<tr key={"costcenter_" + this.props.ledger_index + this.props.cost_category_index + this.props.cost_center_index}>
					<td>
					</td>
					<td colSpan={2}>
						<Select
							// className="form-control"
							className="Select-Search "
							classNamePrefix={(!!this.props.is_leder_recommended ? "Select SelectLedger input-bg-green " : "Select cost center ledger_cc")}
							//classNamePrefix="Select cost center ledger_cc"
							value={
								this.props.cost_center_list.filter(option => option.value == this.state.cost_center_guid).map(cost_center => ({ "label": decodeHtml(cost_center.name), "value": cost_center.guid }))
							}
							isDisabled={!this.state.is_permission_granted || read_only || !this.props.cost_categories_list.length}
							isClearable={false}
							isRtl={false}
							isSearchable={true}
							name="cost_center_option"
							placeholder="Select Cost Center..."
							options={this.props.cost_center_list}
							styles={SelectSearch}
							onChange={(e) => this.handleCostCenterChange(e, 'ledger')}
						/>
						<small className="error_right">
							{(!this.props.is_valid_ledger) && this.costCenterErrorMessage()}
						</small>
					</td>
					<td>
						<CurrencyInput
							type="text"
							className={"form-control text-right "+ (!!this.props.is_leder_recommended ? "input-bg-green " : "")}
							name="cost_center_amount"
							autoComplete="off"
							decimalsLimit={2}
							prefix="&#8377; "
							value={this.state.cost_center_amount}
							allowNegativeValue={false}
							disabled={!this.state.is_permission_granted || read_only || this.props.is_bulk_ledger || !this.props.cost_categories_list.length}
							onValueChange={(value, name) => this.amountChange(value, name)}
							onBlur={() => {
								this.setState({cost_center_amount : this.props.amount_round_off ? roundOffValue(this.props.amount_round_off,this.state.cost_center_amount) : this.state.cost_center_amount},()=>{
									this.onBlurCostCenterAMt()
								})
								}}
						/>
						<small className="error_right d-block mb-0">{(!this.props.is_valid_ledger) && this.costCenterAmtErrorMessage()}</small>
					</td>
					{/* <td className="text-right p-0" >
						{(!!this.props.cost_center_index && !(!this.state.is_permission_granted || read_only)) &&
							<span class="icon-Cross-with-grey-circle" onClick={() => this.props.removeCostCenter(this.props.cost_center_index)}><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
						}
					</td> */}

					<td className='croslt'>
						{(!!this.props.cost_center_index && !(!this.state.is_permission_granted || read_only)) &&
							<span class="icon-Cross-with-grey-circle croslt-icon" onClick={() => this.props.removeCostCenter(this.props.cost_center_index)}><span class="path1"></span><span class="path2 cpointer"></span><span class="path3"></span></span>
						}
					</td>

				</tr>
			</>
		)
	}
}

function mapStateToProps(state) {
	const session_value = state.Session || {};
	const features_permissions_list = session_value.features_permissions_list || [];
	const money_link_values = state.MoneyLink || {};
	const cost_categories_list = money_link_values.cost_category_list || []
	return {
		features_permissions_list, cost_categories_list
	};
}

export default connect(mapStateToProps)(CostCenter);