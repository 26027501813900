import { roundOffValue } from './Roundoff';

function validateCreateEvidenceWithReadyState(data,isReady) {
 
  let errors = {};

  if(!isReady){
    if (!!data.gst_no && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_no)) {
      errors.gst_no = 'Invalid GST No.';
    }

    if (!!data.pan_number && !/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/.test(data.pan_number)) {
      errors.pan_number = 'Invalid Pan No.';
    }else if (!!data.pan_number && !!data.gst_no && data.gst_no.substring(2, 12) != data.pan_number) {
      errors.pan_number = 'Pan No. and GST No. should match';  
      if(data.accounting_status != 3) {
        data.entry_status = 2;
      }
    }
    if(data.accounting_mode == 2 || data.accounting_mode  == 1) {
      if (data.gst_status) {
        if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount)) {
          errors.cgst_amount = 'CGST is required';
          errors.sgst_amount = 'SGST is required';
         
        }
        if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount) && data.inventory_mode) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("here");
        
        }
        if (!data.cgst_amount && !data.sgst_amount && !data.igst_amount && data.inventory_mode == false) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("here in ");
        
        }
        if (!!data.cgst_amount && !!data.sgst_amount && !!data.igst_amount) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("there");
       
        }
        // if(data.cgst_amount == 0 && data.sgst_amount == 0 && Number.isNaN(data.igst_amount) && data.inventory_mode == false){
        //   errors.cgst_amount = 'CGST is required';
        //   errors.sgst_amount = 'SGST is required';
        // }
        if (Number.isNaN(data.sgst_amount) && !Number.isNaN(data.cgst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
          errors.sgst_amount = 'SGST is required';
        
        }
        if (!Number.isNaN(data.sgst_amount) && Number.isNaN(data.cgst_amount) && (data.entry_status != 1|| data.accounting_status != 3)) {
          errors.cgst_amount = 'CGST is required';
        
        }
        if (Number.isNaN(data.gst_total_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
          errors.gst_total_amount = 'total is required';
        
        }
      }
    }

    if(data.accounting_mode == 1 || data.accounting_mode == 2) {
      if( data.gst_status && ( (!!data.sgst_amount === true && !!data.cgst_amount === false) || (!!data.sgst_amount === false && !!data.cgst_amount === true) ) || ( (!!data.sgst_amount || !!data.cgst_amount) && (data.sgst_amount != data.cgst_amount) )  ){
        errors.same_amount = 'CGST & SGST amounts must match.';
        console.log("888888");
      }
    }
  
  }
  else{
    if (!data.date) {
      errors.date = 'Date is required';
    }
  
    if (!data.merchant_name && (!!data.gst_no || !!data.pan_number)) {
      errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
    }
  
    if (!data.merchant_name && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
    } else if (!/^[a-zA-Z0-9]{1}/.test(data.merchant_name) && !!data.merchant_name) {
      errors.merchant_name = 'Invalid Name';
    } else if (!!data.merchant_name && data.merchant_name.length > 100) {
      errors.merchant_name = 'Length must be between 1 and 100';
    }
   
    if (!!data.gst_no && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_no)) {
      errors.gst_no = 'Invalid GST No.';
    }
  
    if (!!data.pan_number && !/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/.test(data.pan_number)) {
      errors.pan_number = 'Invalid Pan No.';
    }else if (!!data.pan_number && !!data.gst_no && data.gst_no.substring(2, 12) != data.pan_number) {
      errors.pan_number = 'Pan No. and GST No. should match';  
      if(data.accounting_status != 3) {
        data.entry_status = 2;
      }
    }
  
    if (!data.total_amount && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.total_amount = 'Total amount is required';
    }
  
    if (!data.invoice_number && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.invoice_number = 'No. is required';
    }
  
    if(data.accounting_mode == 2 || data.accounting_mode  == 1) {
      if (data.gst_status && (data.entry_status != 1 || data.accounting_status != 3)) {
        if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount)) {
          errors.cgst_amount = 'CGST is required';
          errors.sgst_amount = 'SGST is required';
        }
        if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount) && data.inventory_mode) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("here")
        }
        if (!data.cgst_amount && !data.sgst_amount && !data.igst_amount && data.inventory_mode == false) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("here in ")
        }
        if (!!data.cgst_amount && !!data.sgst_amount && !!data.igst_amount) {
          errors.igst_amount = 'Please fill either IGST or CGST & SGST';
          console.log("there")
        }
        // if(data.cgst_amount == 0 && data.sgst_amount == 0 && Number.isNaN(data.igst_amount) && data.inventory_mode == false){
        //   errors.cgst_amount = 'CGST is required';
        //   errors.sgst_amount = 'SGST is required';
        // }
        if (Number.isNaN(data.sgst_amount) && !Number.isNaN(data.cgst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
          errors.sgst_amount = 'SGST is required';
        }
        if (!Number.isNaN(data.sgst_amount) && Number.isNaN(data.cgst_amount) && (data.entry_status != 1|| data.accounting_status != 3)) {
          errors.cgst_amount = 'CGST is required';
        }
        if (Number.isNaN(data.gst_total_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
          errors.gst_total_amount = 'total is required';
        }
      }
    }
  
    if (data.tds_status  && (data.entry_status != 1 || data.accounting_status != 3)) {
  
      if (!data.tds_percentage) {
        errors.tds_percentage = 'TDS % is required';
      }
      if (!data.tds_amount) {
        errors.tds_amount = 'TDS amount is required';
      }
    }
  
  
    if (data.payment_status && data.payment_information && (data.entry_status != 1 || data.accounting_status != 3)) {
      for (let i = 0; i < data.payment_information.length; i++){
        if (parseInt(data.payment_information[i].method) == 0) {
          errors['payment_information_method_' + i] = 'Payment method is required';
        }
      }
    }
  
    if (data.payment_status && data.payment_information && (data.entry_status != 1 || data.accounting_status != 3)) {
      for (let i = 0; i < data.payment_information.length; i++){
        if (!parseInt(data.payment_information[i].amount)) {
          errors['payment_information_' + i] = 'Amount is required';
        }
      }
    }
  
    if(data.accounting_mode == 1 || data.accounting_mode == 2) {
      if( data.gst_status && ( (!!data.sgst_amount === true && !!data.cgst_amount === false) || (!!data.sgst_amount === false && !!data.cgst_amount === true) ) || ( (!!data.sgst_amount || !!data.cgst_amount) && (data.sgst_amount != data.cgst_amount) )  ){
        errors.same_amount = 'CGST & SGST amounts must match.';
      }
    }
  
    if(data.is_reimbursement && data.total_amount < data.approved_amount) {
      errors.approved_amount = 'Reimbursement Amount cannot be greater than Total Amount';
    }
  
    if(data.is_reimbursement && !data.approved_amount) {
      errors.approved_amount = 'Reimbursement Amount is required';
    }
  }
 

  return errors;
}



function validateCreateEvidence(data) {
  console.log("create evidance data",data)
  let errors = {};

  if (!data.date) {
    errors.date = 'Date is required';
  }

  if (!data.merchant_name && (!!data.gst_no || !!data.pan_number)) {
    errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
  }

  if (!data.merchant_name && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
  } else if (!/^[a-zA-Z0-9]{1}/.test(data.merchant_name) && !!data.merchant_name) {
    errors.merchant_name = 'Invalid Name';
  } else if (!!data.merchant_name && data.merchant_name.length > 100) {
    errors.merchant_name = 'Length must be between 1 and 100';
  }
 
  if (!!data.gst_no && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_no)) {
    errors.gst_no = 'Invalid GST No.';
  }

  if (!!data.pan_number && !/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/.test(data.pan_number)) {
    errors.pan_number = 'Invalid Pan No.';
  }else if (!!data.pan_number && !!data.gst_no && data.gst_no.substring(2, 12) != data.pan_number) {
    errors.pan_number = 'Pan No. and GST No. should match';  
    if(data.accounting_status != 3) {
      data.entry_status = 2;
    }
  }

  if (!data.total_amount && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.total_amount = 'Total amount is required';
  }

  if(data.inventory_details && data.inventory_details.line_item_total && data.inventory_details.line_item_total != data.total_amount  && (data.entry_status != 1 || data.accounting_status != 3)){
    errors.total_amount = 'The total amount does not match with the inventory line items total.';
  }
  if (!data.invoice_number && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.invoice_number = 'No. is required';
  }
  let res = data.amount_round_off ? roundOffValue(data.amount_round_off,(data.total_amount - data.gst_total_amount)):(data.total_amount - data.gst_total_amount).toFixed(2);
  console.log('res --- ' ,data.amount_round_off, res);

  if(data.accounting_mode == 2 || data.accounting_mode  == 1) {
    if (data.gst_status && (data.entry_status != 1 || data.accounting_status != 3)) {
      if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount)) {
        errors.cgst_amount = 'CGST is required';
        errors.sgst_amount = 'SGST is required';
      }
      if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount) && data.inventory_mode) {
        errors.igst_amount = 'Please fill either IGST or CGST & SGST';
        console.log("here")
      }
      if (!data.cgst_amount && !data.sgst_amount && !data.igst_amount && data.inventory_mode == false) {
        errors.igst_amount = 'Please fill either IGST or CGST & SGST';
        console.log("here in ")
      }
      if (!!data.cgst_amount && !!data.sgst_amount && !!data.igst_amount) {
        errors.igst_amount = 'Please fill either IGST or CGST & SGST';
        console.log("there")
      }
      // if(data.cgst_amount == 0 && data.sgst_amount == 0 && Number.isNaN(data.igst_amount) && data.inventory_mode == false){
      //   errors.cgst_amount = 'CGST is required';
      //   errors.sgst_amount = 'SGST is required';
      // }
      if (Number.isNaN(data.sgst_amount) && !Number.isNaN(data.cgst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
        errors.sgst_amount = 'SGST is required';
      }
      if (!Number.isNaN(data.sgst_amount) && Number.isNaN(data.cgst_amount) && (data.entry_status != 1|| data.accounting_status != 3)) {
        errors.cgst_amount = 'CGST is required';
      }
      if (Number.isNaN(data.gst_total_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
        errors.gst_total_amount = 'total is required';
      }

      if(data.tds_details.length > 0 && data.tds_status){
        const totalBaseAmt = data.tds_details.reduce((accumulator, item) => {
          return accumulator + (item.base_amount || 0);
        }, 0);
  
        if(data.gst_total_amount > totalBaseAmt){
          errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
        }
      }
      else{
        if(data.gst_total_amount > res){
          errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
        }
      }
    }
  }

  if (data.tds_status){// && (data.entry_status != 1 || data.accounting_status != 3)) {
    
    // if (!data.tds_percentage) {
    //   errors.tds_percentage = 'TDS % is required';
    // }
    // if (!data.tds_amount) {
    //   errors.tds_amount = 'TDS amount is required';
    // }

    var tdsError = data.tds_details.map((item, index) => {
      let err = {};
      console.log("tds item while errro ----------" , item , res );
      if (!item.tds_percentage) {
        err.tds_percentage = 'TDS % is required';
      }
      if (!item.tds_amount) {
        err.tds_amount = 'TDS amount is required';
      }
    
      if (!item.base_amount) {
        err.base_amount = 'TDS base amount is required';
      }

     if(item.base_amount && parseFloat(item.base_amount) > parseFloat(res))
      err.base_amount = 'TDS base amount should not be greater than Base Amount';

      // if(index == 0 && item.tds_amount && item.tds_amount > (data.total_amount - data.gst_total_amount)){
      //   err.tds_amount = 'TDS amount should not be higher than Base Amount';
      // }

      return err;
    });
    
    const totalBaseAmt = data.tds_details.reduce((accumulator, item) => {
      return accumulator + (item.base_amount || 0);
    }, 0);

    console.log("total base amount ---" , totalBaseAmt);
    if(totalBaseAmt > res)
      errors.total_base_amount = 'Sum of TDS Base Amount should not be greater than Base Amount (Total Amount - GST Total)';

    let istdsDetailsErr = tdsError.every((item) =>
    Object.keys(item).length === 0
    );
      //  console.log("tds array length 9999999999999999", tdsError.every((item) =>
      //                      Object.keys(item).length === 0
      //                     ),tdsError.length , tdsError);
    
    if(!istdsDetailsErr){
      errors.tds_details = tdsError;
    }
    console.log("errors ---------------" , errors);
  }


  // if (data.payment_status && data.payment_information && (data.entry_status != 1 || data.accounting_status != 3)) {
  //   for (let i = 0; i < data.payment_information.length; i++){
  //     if (parseInt(data.payment_information[i].method) == 0) {
  //       errors['payment_information_method_' + i] = 'Payment method is required';
  //     }
  //   }
  // }

  // if (data.payment_status && data.payment_information && (data.entry_status != 1 || data.accounting_status != 3)) {
  //   for (let i = 0; i < data.payment_information.length; i++){
  //     if (!parseInt(data.payment_information[i].amount)) {
  //       errors['payment_information_' + i] = 'Amount is required';
  //     }
  //   }
  // }

  if (data.payment_status && data.payment_information) {
    for (let i = 0; i < data.payment_information.length; i++){
      if (parseInt(data.payment_information[i].method) == 0) {
        errors['payment_information_method_' + i] = 'Payment method is required';
      }
    }
  }

  if (data.payment_status && data.payment_information ) {
    for (let i = 0; i < data.payment_information.length; i++){
      if (!parseInt(data.payment_information[i].amount)) {
        errors['payment_information_' + i] = 'Amount is required';
      }
    }
  }


  if(data.accounting_mode == 1 || data.accounting_mode == 2) {
    if( data.gst_status && ( (!!data.sgst_amount === true && !!data.cgst_amount === false) || (!!data.sgst_amount === false && !!data.cgst_amount === true) ) || ( (!!data.sgst_amount || !!data.cgst_amount) && (data.sgst_amount != data.cgst_amount) )  ){
      errors.same_amount = 'CGST & SGST amounts must match.';
    }
  }

  if(data.is_reimbursement && data.total_amount < data.approved_amount) {
    errors.approved_amount = 'Reimbursement Amount cannot be greater than Total Amount';
  }

  if(data.is_reimbursement && !data.approved_amount) {
    errors.approved_amount = 'Reimbursement Amount is required';
  }

  return errors;
}

function validateInventoryModeData(data) {

  console.log("inventory data",data)
  let errors = {};
	let line_item_errors = [];
  let ledger_errors = [];

  //console.log(data)
  //SOC::mandatory fields for accounting status
  if ((data.accounting_status != 3) && data.inventory_voucher_status && data.accounting_user) {
   
    if (!data.voucher_creation_date) {
      errors.voucher_creation_date = "Please select Voucher Date"
    }
    if (!data.party_ledger_guid) {
      errors.party_ledger_guid = "Please select Party Ledger"
    }
    if (!data.common_item_ledger_guid && data.is_common_item_ledger == 1) {
      if (data.type == 1) {
        errors.common_item_ledger_guid = "Please select Sales Ledger"
      } else {
        errors.common_item_ledger_guid = "Please select Purchase Ledger"
      }
    }

    let line_item_err = 0;
    data.line_items.forEach((item, index) => {
      if (!item.line_item_name && !item.hsn_sac && !item.rate && !item.per && !item.quantity && !item.gst_rate && !item.discount_rate && !item.item_ledger_guid && !item.common_item_ledger_guid) {
        line_item_err += 1
      }
    })

    if(data.line_items.length == line_item_err){
      errors.line_item_err = true;
    }
  }
  //EOC::mandatory fields for accounting status

  if ((data.accounting_status != 3) && !data.inventory_voucher_status && data.accounting_user) {
    let line_item_err = 0;
    data.line_items.forEach((item, index) => {
      if (!item.line_item_name && !item.hsn_sac && !item.rate && !item.per && !item.quantity && !item.gst_rate && !item.discount_rate) {
        line_item_err += 1
      }
    })

    if(data.line_items.length == line_item_err){
      errors.line_item_err = true;
    }
  }

  //SOC::mandatory fields for entry status
  if (data.entry_status != 1 || data.accounting_status != 3) {
    data.line_items.forEach((item, index) => {
      if (item.line_item_name || item.hsn_sac || item.rate || item.per || item.quantity || item.gst_rate || item.discount_rate || item.item_ledger_guid || item.common_item_ledger_guid) {

        let line_item_name_error, quantity_error, rate_error, amount_error, item_ledger_guid_error, common_item_ledger_guid_error, per_error = false;

        if (!item.line_item_name) 
          line_item_name_error = true;

        if (!item.quantity || parseFloat(item.quantity) <= 0)
          quantity_error = true;

        if (!item.rate) 
          rate_error = true;

        if (!item.amount) 
          amount_error = true;
      

        if (data.inventory_voucher_status && data.accounting_user && data.accounting_status != 3) {
          if (!item.item_ledger_guid) {
            item_ledger_guid_error = true;
          }
          if (!item.common_item_ledger_guid && !data.is_common_item_ledger) {
            common_item_ledger_guid_error = true;
          }

          if (!item.per) 
            per_error = true;
        }

        if(line_item_name_error || quantity_error || rate_error || amount_error || item_ledger_guid_error || common_item_ledger_guid_error || per_error) {

          line_item_errors[index] = {};

          if(line_item_name_error)
            line_item_errors[index].line_item_name = true;

          if(quantity_error)
            line_item_errors[index].quantity = true;

          if(rate_error)
            line_item_errors[index].rate = true;

          if(amount_error)
            line_item_errors[index].amount = true;

          if(item_ledger_guid_error)
            line_item_errors[index].item_ledger_guid = true;

          if(common_item_ledger_guid_error)
            line_item_errors[index].common_item_ledger_guid = true;
          
          if(per_error)
            line_item_errors[index].per = true;
      
        }
      }
    })

    data.ledgers.forEach((led, index) => {
      if(parseFloat(led.amount) > 0 || led.rate || led.guid){
        let  rate_error, amount_error, guid_error = false;

        if (data.inventory_voucher_status && data.accounting_user && data.accounting_status != 3) {
          if (!led.amount) {
            amount_error = true;
          }
          if (!led.guid) {
            guid_error = true;
          }
          
          if (amount_error || guid_error) {

            ledger_errors[index] = {};

            if (amount_error)
              ledger_errors[index].amount = true;

            if (guid_error)
              ledger_errors[index].guid = true;

          }

        }
      }
    })

    if (data.is_igst) {
      if (Number.isNaN(data.igst_amount)) {
        errors.igst_amount = 'Please fill IGST';
      }
    } else {
      if (data.cgst_amount && !data.sgst_amount && data.entry_status != 1) {
        errors.sgst_amount = 'Please fill SGST';
      }

      if (!data.cgst_amount && data.sgst_amount && data.entry_status != 1) {
        errors.cgst_amount = 'Please fill CGST';
      }
    }

    if ((data.cgst_amount || data.sgst_amount) && data.igst_amount) {
      errors.igst_amount = 'Please fill either IGST or CGST & SGST';
    }
    
    // if (data.tds_status) {
  
    //   if (!data.tds_percentage) {
    //     errors.tds_percentage = 'TDS % is required';
    //   }
    //   if (parseFloat(data.tds_amount) <= 0) {
    //     errors.tds_amount = 'TDS amount is required';
    //   }
    // }
    if (data.tds_status){// && (data.entry_status != 1 || data.accounting_status != 3)) {
    
      // if (!data.tds_percentage) {
      //   errors.tds_percentage = 'TDS % is required';
      // }
      // if (!data.tds_amount) {
      //   errors.tds_amount = 'TDS amount is required';
      // }
  
      var tdsError = data.tds_details.map((item, index) => {
        let err = {};
        console.log("tds item while errro ----------" , item);
        if (!item.tds_percentage) {
          err.tds_percentage = 'TDS % is required';
        }
        if (!item.tds_amount) {
          err.tds_amount = 'TDS amount is required';
        }
      
        if (!item.base_amount) {
          err.base_amount = 'TDS base amount is required';
        }
        return err;
      });
      
      let istdsDetailsErr = tdsError.every((item) =>
      Object.keys(item).length === 0
      );
        //  console.log("tds array length 9999999999999999", tdsError.every((item) =>
        //                      Object.keys(item).length === 0
        //                     ),tdsError.length , tdsError);
      
      if(!istdsDetailsErr){
        errors.tds_details = tdsError;
      }
      console.log("errors ---------------" , errors);
    }
  
  }

  if (!data.is_igst) {
    if (data.cgst_amount && data.sgst_amount && data.cgst_amount !== data.sgst_amount) {
      errors.same_amount = 'CGST & SGST amounts must match';
    }
  }

  if(line_item_errors.length > 0) {
    errors.line_item_errors = line_item_errors
  }

  // if(ledger_errors.length > 0) {
  //   errors.ledger_errors = ledger_errors
  // }

  let final_errors = {};

  final_errors.errors = errors
  final_errors.line_item_errors = line_item_errors;
  final_errors.ledger_errors = ledger_errors;

  return final_errors;
}

function validateUpdateEvidence(data) {
  let errors = {};

  if (!data.type) {
    errors.type = 'Type is required';
  }

  if (!data.date) {
    errors.date = 'Date is required';
  }

  //console.log(data.merchant_name)
  if (!data.merchant_name && (!!data.gst_no || !!data.pan_number)) {
    errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
  }

  if (!data.merchant_name && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.merchant_name = data.expense ? 'Party Name is required' : 'Party Name is required';
  } else if (!/^[a-zA-Z0-9]{1}/.test(data.merchant_name) && !!data.merchant_name) {
    errors.merchant_name = 'Invalid Name';
  } else if (!!data.merchant_name && data.merchant_name.length > 100) {
    errors.merchant_name = 'Length must be between 1 and 100';
  }

  if (!!data.gst_no && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(data.gst_no)) {
    errors.gst_no = 'Invalid GST No.';
  }

  if (!!data.pan_number && !/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/.test(data.pan_number)) {
    errors.pan_number = 'Invalid Pan No.';
  }else if (!!data.pan_number && !!data.gst_no && data.gst_no.substring(2, 12) != data.pan_number) {
    errors.pan_number = 'Pan No. and GST No. should match';
  }

  if (!data.total_amount && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.total_amount = 'Total amount is required';
  }

  if(data.inventory_details && data.inventory_details.line_item_total && data.inventory_details.line_item_total != data.total_amount  && (data.entry_status != 1 || data.accounting_status != 3)){
    errors.total_amount = 'The total amount does not match with the inventory line items total.';
  }
  if (!data.invoice_number && (data.entry_status != 1 || data.accounting_status != 3)) {
    errors.invoice_number = 'No. is required';
  }

  let res = data.amount_round_off ? roundOffValue(data.amount_round_off,(data.total_amount - data.gst_total_amount)):(data.total_amount - data.gst_total_amount).toFixed(2);
  if (data.gst_status && (data.entry_status != 1 || data.accounting_status != 3)) {
    if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.cgst_amount = 'CGST is required';
      errors.sgst_amount = 'SGST is required';
    }

    if (Number.isNaN(data.cgst_amount) && Number.isNaN(data.sgst_amount) && Number.isNaN(data.igst_amount) && data.inventory_mode) {
      errors.igst_amount = 'Please fill either IGST or CGST & SGST';
    }
    if (!data.cgst_amount && !data.sgst_amount && !data.igst_amount && data.inventory_mode == false) {
      errors.igst_amount = 'Please fill either IGST or CGST & SGST';
      console.log("here")
    }
    if (!!data.cgst_amount && !!data.sgst_amount && !!data.igst_amount) {
      errors.igst_amount = 'Please fill either IGST or CGST & SGST';
    }

    if (Number.isNaN(data.sgst_amount) && !Number.isNaN(data.cgst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.sgst_amount = 'SGST is required';
    }
    if (!Number.isNaN(data.sgst_amount) && Number.isNaN(data.cgst_amount) && (data.entry_status != 1 || data.accounting_status != 3)) {
      errors.cgst_amount = 'CGST is required';
    }
    if (Number.isNaN(data.gst_total_amount)) {
      errors.gst_total_amount = 'total is required';
    }

    if(data.tds_details.length > 0 && data.tds_status){
      const totalBaseAmt = data.tds_details.reduce((accumulator, item) => {
        return accumulator + (item.base_amount || 0);
      }, 0);

      if(data.gst_total_amount > totalBaseAmt){
        errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
      }
    }
    else{
      if(data.gst_total_amount > res){
        errors.gst_total_amount = 'GST amount should not be higher than Base Amount';
      }
    }
  }

  if (data.tds_status){// && (data.entry_status != 1 || data.accounting_status != 3)) {

    // if (!data.tds_percentage) {
    //   errors.tds_percentage = 'TDS % is required';
    // }
    // if (!data.tds_amount) {
    //   errors.tds_amount = 'TDS amount is required';
    // }
  var tdsError = data.tds_details.map((item, index) => {
  let err = {};
  console.log("tds item while errro ----------" , item);
  if (!item.tds_percentage) {
    err.tds_percentage = 'TDS % is required';
  }
  if (!item.tds_amount) {
    err.tds_amount = 'TDS amount is required';
  }

  if (!item.base_amount) {
    err.base_amount = 'TDS base amount is required';
  }

  if(item.base_amount && parseFloat(item.base_amount) > parseFloat(res))
    err.base_amount = 'TDS base amount should not be greater than Base Amount';


  // if(index == 0 && item.tds_amount && item.tds_amount > (data.total_amount - data.gst_total_amount)){
  //   err.tds_amount = 'TDS amount should not be higher than Base Amount';
  // }
  return err;
});

const totalBaseAmt = data.tds_details.reduce((accumulator, item) => {
  return accumulator + (item.base_amount || 0);
}, 0);

console.log("total base amount ---" , totalBaseAmt);
if(totalBaseAmt > res)
  errors.total_base_amount = 'Sum of TDS Base Amount should not be greater than Base Amount (Total Amount - GST Total)';

let istdsDetailsErr = tdsError.every((item) =>
Object.keys(item).length === 0
);
  //  console.log("tds array length 9999999999999999", tdsError.every((item) =>
  //                      Object.keys(item).length === 0
  //                     ),tdsError.length , tdsError);

if(!istdsDetailsErr){
  errors.tds_details = tdsError;
}
    // errors.tds_details = data.tds_details.map((item, index) => {
    //   let err = {};
    //   console.log("tds item while errro ----------" , item);
    //   if (!item.tds_percentage) {
    //     err.tds_percentage = 'TDS % is required';
    //   }
    //   if (!item.tds_amount) {
    //     err.tds_amount = 'TDS amount is required';
    //   }

    //   if (!item.base_amount) {
    //     err.base_amount = 'TDS base amount is required';
    //   }
    //   return err;
    // });
   console.log("tds detail arrow -----------------" , errors);
  }

  if (data.payment_status == 1 && data.payment_information ) {
    for (let i = 0; i < data.payment_information.length; i++){
      console.log(parseInt(data.payment_information[i].method));
      if (parseInt(data.payment_information[i].method) == 0) {
        errors['payment_information_method_' + i] = 'Payment method is required';
      }
    }
  }

  if (data.payment_status == 1 && data.payment_information) {
    for (let i = 0; i < data.payment_information.length; i++) {
       if (!parseInt(data.payment_information[i].amount)) {
         errors['payment_information_' + i] = 'Amount is required';
       }
    }
  }

  if( data.gst_status && ( (!!data.sgst_amount === true && !!data.cgst_amount === false) || (!!data.sgst_amount === false && !!data.cgst_amount === true) ) || ( (!!data.sgst_amount || !!data.cgst_amount) && (data.sgst_amount != data.cgst_amount) )  ){
    errors.same_amount = 'CGST & SGST amounts must match.';
  }

  if(data.is_reimbursement && data.total_amount < data.approved_amount) {
    errors.approved_amount = 'Reimbursement Amount cannot be greater than Total Amount';
  }

  if(data.is_reimbursement && !data.approved_amount) {
    errors.approved_amount = 'Reimbursement Amount is required';
  }
  
  //console.log(errors)
  return errors;
}

function checkValidGSTNo(gst_no){
  return (!!gst_no && /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(gst_no));
}

// validation schema for import excel module

function validationUpdateExcelImport(data){
  let errors = {};

  // console.log("check:::validationUpdateExcelImport::data", data)
  data.forEach((row, index) => {
    // console.log("check7:::valData:::row", row["GST ELIGIBLE"])
    // console.log("check7:::valData:::row", row)
    if (row.disabled) {
      return;
    }
   
    // if(row['PARTY NAME'] === ""){
    //   errors[index] = { ...errors[index], party_name: 'Party Name should not be empty' };
    // }
    // if (
    //   row['PARTY NAME'] === ""
      
    // ) {
    //   errors[index] = { ...errors[index], party_name: 'party name should not empty' };
    // }

    if (
      !!row['PARTY GST'] &&
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
        row['PARTY GST']
      )
    ) {
      errors[index] = { ...errors[index], gst_no: 'Invalid GST No.' };
    }

    if (
      !!row['PARTY PAN'] &&
      !/^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]$/.test(row['PARTY PAN'])
    ) {
      errors[index] = { ...errors[index], pan_number: 'Invalid Pan No.' };
    }

    if(!!row['TOTAL AMOUNT'] && isNaN(row['TOTAL AMOUNT'])){
      errors[index] = { ...errors[index], total_amount: 'total amount should be number' };
    }

    if(!!row['CGST'] && isNaN(row['CGST'])){
      errors[index] = { ...errors[index], cgst_amount: 'CGST should be number' };
    }else if(row["GST ELIGIBLE"] === true && row["IGST"] ==="" &&row["SGST"] ===""&&row["CGST"] === ''){
      errors[index] = { ...errors[index], cgst_amount: 'If GST Eligible check then CGST should not be empty', sgst_amount: 'If GST Eligible check then SGST should not be empty', igst_amount: 'If GST Eligible check then IGST should not be empty' };
    }

    

    if(!!row['SGST'] && isNaN(row['SGST'])){
      errors[index] = { ...errors[index], sgst_amount: 'SGST should be number' };
    }else if (!!row['SGST'] && row["GST ELIGIBLE"] === true && row['CGST'] !== row['SGST'] ){
      errors[index] = { ...errors[index], sgst_amount: 'SGST amount should be same CGST' };
    }
    else if (!!row['CGST'] && row["GST ELIGIBLE"] === true && row['CGST'] !== '' && row['SGST'] === '') {
      errors[index] = { ...errors[index], sgst_amount: 'SGST should be same with CGST amount' };
    }

    if(!!row['IGST'] && isNaN(row['IGST'])){
      errors[index] = { ...errors[index], igst_amount: 'IGST should be number' };
    }else if (!!row['CGST'] && !!row['SGST'] && row['IGST'] !== '') {
      errors[index] = { ...errors[index], igst_amount: 'IGST should be empty if both CGST and SGST are fill' };
    }

    // Additional IGST related validation
    if (!!row['IGST'] && row['IGST'] !== '') {
      if (!!row['CGST'] && row['CGST'] !== '') {
        errors[index] = { ...errors[index], cgst_amount: 'CGST should be empty, If IGST amount fill' };
      }
      if (!!row['SGST'] && row['SGST'] !== '') {
        errors[index] = { ...errors[index], sgst_amount: 'SGST should be empty, If IGST amount fill' };
      }
    }

    // for tds %
    if(!!row['TDS %'] && isNaN(row['TDS %'])){
      errors[index] = { ...errors[index], tds_percentage: 'TDS  percentage should be number' };
    }else if (row['TDS %'] > 100){
      errors[index] = { ...errors[index], tds_percentage: 'TDS  percentage should be less than 100' };
    }

     // Check for your new condition here
     

     // Validate GST ELIGIBLE related fields
  //    if (row["GST ELIGIBLE"] === true ) {
  //     if (row["CGST"] === '') {
  //         errors[index] = { ...errors[index], cgst_amount: 'If GST Eligible check then CGST should not be empty' };
  //     }
  //     if (row["SGST"] === '') {
  //         errors[index] = { ...errors[index], sgst_amount: 'If GST Eligible check then SGST should not be empty' };
  //     }
  //     if (row["IGST"] === '') {
  //         errors[index] = { ...errors[index], igst_amount: 'If GST Eligible check then IGST should not be empty' };
  //     }
  // }

    if(row.entry_status === 1){
      if (
        row['PARTY NAME'] === ""
        
      ) {
        errors[index] = { ...errors[index], party_name: 'party name should not empty' };
      }
      if( row['REFERENCE NO'] === ""){
        errors[index] = { ...errors[index], refrence_no: 'Refrence No. is required' };
      }

      if (!!row['CGST'] && row['CGST'] !== '' && row['SGST'] === '') {
        errors[index] = { ...errors[index], sgst_amount: 'SGST cannot be empty if CGST is not empty' };
      }

      if( row['TOTAL AMOUNT'] === ""){
        errors[index] = { ...errors[index], total_amount: 'Total amount is required' };
      }
    }
    // if (row.disabled) {
    //   return;
    // }
  });

  // console.log("check:::validationUpdateExcelImport:::validation", errors)

  return errors;
}

export {
  validateUpdateEvidence,
  validateCreateEvidence,
  checkValidGSTNo,
  validateInventoryModeData,
  validationUpdateExcelImport,
  validateCreateEvidenceWithReadyState
}